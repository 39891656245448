import { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { translations } from '../Helpers/Lang';

import SeasonItem from '../Components/SeasonItem';



function GoToSeason( props ){

	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])


	return (
		<div className="gotoseason">

			{ props.season !== null && props.season !== undefined && props.season.translations !== undefined && props.season.translations.length > 0 && 
				<Fragment>

					<NavLink  
						to={`/${props.lang}/${props.season.slug}`}
					>
						<h4>{translations[props.lang].label_next}</h4>

						<SeasonItem 
							key={`goto_season`} 
							classPrefix={'hometripple'}
							season={props.season}
							lang={props.lang}
						/>
						
					</NavLink>

				</Fragment>
			}
			
		</div>
	)
}

export default GoToSeason;