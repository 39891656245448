import {Fragment, useEffect, useState} from "react";
import { /*language,*/ translations } from '../Helpers/Lang';
import { useNavigate } from "react-router-dom";
import { IoIosArrowUp, IoLogoFacebook, IoLogoInstagram } from "react-icons/io";


function Footer(props){


	const navigate = useNavigate();
	
	const [currentSlug, setCurrentSlug] = useState('');

	useEffect(() => {
	// eslint-disable-next-line
	},[])

	useEffect(() => {
		let urlSegments = window.location.href.split('/');
		if( urlSegments[4] !== undefined ){
			setCurrentSlug( urlSegments[4] );
		}else{
			setCurrentSlug( '' );
		}
	// eslint-disable-next-line
	},[window.location.href])

	/*
	const setLanguage = (lang) => {
		// setMenuVisible(false);
		props.setLang(lang);
		navigate( `/${lang}` );
		window.scrollTo(window.scrollX, 0);
	}
	*/

	const setPage = (page) => {
		navigate( `/${props.lang}/${page}` );
		window.scrollTo(window.scrollX, 0);
		// history.push( `${process.env.PUBLIC_URL}/${props.lang}/${page}` );
	}

	return (
		<div className="footer">
			
			<div className="footer__wrapper">
				<div>
					<a href="https://www.eurokaz.hr" rel="noreferrer" target="_blank">
						<img style={{ maxWidth: '185px' }} src="/assets/logos/eurokaz_white.png" alt="Eurokaz" />
					</a>
					<br /><br />
					<span dangerouslySetInnerHTML={{__html: translations[props.lang].footer_left_html }} />
					<br /><br />
					<small>
						<strong>
							<a href="http://www.eurokaz.hr" rel="noreferrer" target="_blank">www.eurokaz.hr</a>
							<br />
							<a href="mailto:info@eurokaz.hr">info@eurokaz.hr</a>
							<br /><br />
							<a href="https://www.facebook.com/EurokazHR" rel="noreferrer" target="_blank">
								<IoLogoFacebook />
							</a>
							<a href="https://www.instagram.com/eurokazhr/" rel="noreferrer" target="_blank">
								<IoLogoInstagram />
							</a>
						</strong>
					</small>
				</div>

				<div className="footer__links">
					{props.lang !== undefined && props.lang !== '' && 
						<Fragment>
							{ props.menu !== undefined && props.menu.length > 0 && 
								props.menu.map( (menuLink, pageIdx) => {
									return <Fragment key={`menu_page_${pageIdx}`}>
										<button
											className={`menu__item menu__item--page ${(currentSlug === menuLink.slug)?'active':''} `}
											onClick={ () => { setPage(menuLink.slug); }}
										>
											{menuLink.title}
										</button>
									</Fragment>
								})
							}
						</Fragment>
					}
				</div>

				<div>
					<p>
						<button onClick={() => {window.scrollTo(0, 0);}}>
							<IoIosArrowUp />
						</button>
					</p>
					{/*
					<p>
						{ props.lang !== undefined 
							&& props.lang !== '' 
							&& <Fragment>
								{languages.map( language => {
									return <button
										key={`lang_${language}`}
										onClick={() => { setLanguage( language ); }}
										className={`typo--uppercase ${(props.lang === language)?'active':''}`}>
										{language}
									</button>
								})}
							</Fragment>
						}
					</p>
					*/}
					<p>
						<small>
							<strong>
								<span dangerouslySetInnerHTML={{__html: translations[props.lang].footer_right_html }} />
							</strong>
						</small>

						<span className="footer__logos">
							<a href="https://kulturanova.hr" rel="noreferrer" target="_blank">
								<img src="/assets/logos/kulturanova_white.png" alt="Kultura Nova" />
							</a>
						</span>
					</p>
				</div>
			</div>

			<div className="footer__bottom" />

		</div>
	);

}

export default Footer;