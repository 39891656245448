import { useEffect, useRef } from "react";
// import Slider from '../Slider';
import Media from '../Media';
import Lightbox from '../Lightbox';



function ArticleMediaGrid ( props ){

	const itemsRef = useRef({});
	
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`} >

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

            <h2 id={props.article.slug}>&nbsp;</h2>

			{ props.article.media.length > 0 && 
				<div className="article__media">
					{props.article.media.map( (media, mediaIdx) => 
						<div 	key={`media_${media.id}`} 
								ref={el => itemsRef.current[`media_${mediaIdx}_${media.id}`] = el}
								className="slider__item" >

                            {/*
							<small className="slider__item-count">
								{`${mediaIdx+1}/${props.article.media.length}`}
							</small>
                            */}

							<Media 
								media={media} 
								lightboxTitle={`<strong>${props.article.title}</strong> <small>${media.title}</small>`}
								lightbox={false}
								cdnParams="&width=1200&func=crop"
							/>

						</div>
					)}
				</div>
			}

			<Lightbox items={itemsRef.current} />
			
		</div>
	);

}

export default ArticleMediaGrid;