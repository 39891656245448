import { Fragment, useRef } from "react";
import Slider from '../Slider';
import Media from '../Media';
import Lightbox from '../Lightbox';


function SectionVerticalMediaHorizontal( props ){

	const itemsRef = useRef({});

	return (
		<div className={`section section--${props.section.display_type || 'vertical_media_horizontal'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

			{props.section.translations.map( (translation,translationIdx) => {
				return <div className="" 
							key={`translation_${translationIdx}`}>

					<div className="section__vertical_media_horizontal-info">
						{ translation.title !== '' && 
							<h2 id={translation.slug}>
								{translation.title}
							</h2>
						}

						{ translation.subtitle !== '' && 
							<h3>{translation.subtitle}</h3>
						}

						{ translation.description !== '' && 
							<p dangerouslySetInnerHTML={{__html: translation.description}} />
						}
					</div>

					{ translation.media.length > 0 && 
						<Fragment>
							<Slider
								{...props}
								type="horizontal">
									{translation.media.map( (media, mediaIdx) =>
										{
											return <div 	key={`media_${media.id}`} 
													ref={el => itemsRef.current[`${translationIdx}_${mediaIdx}_${media.id}`] = el}
													className="slider__item" 
											>
												<small className="slider__item-count">
													{`${mediaIdx+1}/${translation.media.length}`}
												</small>

												<Media 
													media={media}
													lightboxTitle={`<strong>${props.section.translations[0].title}</strong> <small>${translation.title}, ${media.title}</small>`}
													lightbox={true} />
											</div>
										}
									)}
							</Slider>
						</Fragment>
					}

				</div>
			})}

			<Lightbox items={itemsRef.current} />

		</div>
	);

}

export default SectionVerticalMediaHorizontal;