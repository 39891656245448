import { cloneElement, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from './../history';
import axios from 'axios';
import { apiUrl } from '../Helpers/General';

import RouterWrapper from '../Containers/RouterWrapper';


import Menu from '../Components/Menu';

import Home 	from '../Pages/Home';
import HomeTv 	from '../Pages/HomeTv';

import Season 		from '../Pages/Season';
import Terms 		from '../Pages/Terms';
import Page 		from '../Pages/Page';
import Search 		from '../Pages/Search';

import NotFound from '../Pages/NotFound';



const customPages = [
	{
		component: <Home />,
		paths: [
			'',
			'/',
			'/:language',
		]
	},
	{
		component: <HomeTv />,
		paths: [
			'/:language/tv',
		]
	},

	{
		component: <Terms />,
		paths: [
			'/:language/autori',
			'/:language/authors',
		]
	},
	{
		component: <Season />,
		paths: [
			`/:language/:slug`, 
			`/:language/#:hash`
		]
	},{
		component: <Search />,
		paths: [
			`/:language/pretraga`,
			`/:language/search`
		]
	},{
		component: <NotFound />,
		paths: [
			`/:language/404`
		]
	}
]



function Main(props) {

	const [lang, 	setLang] = useState('');
	const [menu, 	setMenu] = useState([]);


	useEffect(() => {
		let urlSegments = window.location.href.split('/');
		let lang = ( urlSegments[3] !== undefined && urlSegments[3] !== '' ) ? urlSegments[3] : 'hr';
		setLang( lang );
		history.push(`/${urlSegments.slice(3,10).join("/")}`);
	},[])

	useEffect(() => {
		if( lang !== undefined && lang !== '' ){
			axios.get(`${apiUrl}${lang}/api/menus`)
				.then(res => res.data)
				.then(data => {
					setMenu(data);
				});
		}
	// eslint-disable-next-line
	},[lang])



	return(
		
		<div className='main'>

			<BrowserRouter history={history} >
				<RouterWrapper>

					<Menu 
						lang={lang}
						menu={menu.filter(x => x.in_menu === "1")}
						setLang={setLang} 
						{...props}
					/>

					{ menu.length > 0 && 

						<Routes>						

							{ customPages.map( customPage => 
								customPage.paths.map((path, index) => 
									<Route 	exact 
										key={index}
										path={`${process.env.PUBLIC_URL}${path}`}
										element={
											cloneElement(
												customPage.component,
												{ 
													menu: menu.filter(x => x.in_menu === "1"),
													lang: lang,
													setLang: setLang,
													...props
												}
											)
											
										} 
									/>
								)
							)}

							{ menu
								.filter(x => x.in_routes === "1")
								.map( menuLink => 
								<Route 	
									key={`route_${menuLink.slug}`} 
									path={`${process.env.PUBLIC_URL}/:language/${menuLink.slug}`}
									element={
										<Page 
											menu={menu.filter(x => x.in_menu === "1")}
											menuLink={menuLink}
											lang={lang} 
											setLang={setLang} 
										/>
									} 
								/>
							)}

						</Routes>
					}

				</RouterWrapper>
			</BrowserRouter>

			<div className="page-transition" />

		</div>
		
		)
		
}
	
export default Main;