import { useEffect } from "react";
// import { useDraggable } from "react-use-draggable-scroll";
import Media from '../Media';

function ArticleTwoHoriz ( props ){

	
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`}> 

			<div className="article__wrapper">

                <div>
                    <div className="article__content">
                        { props.article.title !== '' && 
                            <h2 id={props.article.slug}>
                                {props.article.title}
                            </h2>
                        }

                        { props.article.subtitle !== '' && 
                            <h3>{props.article.subtitle}</h3>
                        }
                        { props.article.description !== '' && 
                            <p dangerouslySetInnerHTML={{__html: props.article.description}} />
                        }

                        { props.article.media.length > 0 && 
                            <div className="article__media">
                                {props.article.media
                                    .filter( (x,key) => key > 0 )
                                    .map( (media, mediaIdx) => 
                                    <Media 
                                        key={`media_${mediaIdx}`} 
                                        media={media} 
                                        cdnParams="&width=1200&func=crop"
                                    />
                                )}
                            </div>
                        }
                    </div>
                </div>

                <div>
                    { props.article.media.length > 0 && 
                        <div className="article__media">
                            {props.article.media
                                .filter( (x,key) => key === 0 )
                                .map( (media, mediaIdx) => 
                                <Media 
                                    key={`media_${mediaIdx}`} 
                                    media={media} 
                                    cdnParams="&width=1200&func=crop"
                                />
                            )}
                        </div>
                    }
                </div>

            </div>
			
		</div>
	);

}

export default ArticleTwoHoriz;