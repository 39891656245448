import { useEffect } from "react";
import ArticlePropagator from "../ArticlePropagator";



function SectionDefault( props ){

	
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])


	return (
		<div className={`section section--${props.section.display_type || 'default'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

			{props.section.translations.map( (translation,translationIdx) => 

				<ArticlePropagator
					key={`article_${translation.id}`}
					translation={translation}
					addClass={`${(props.sectionIdx === 0 && translationIdx === 0)?'article--first':''}`}
					showMoreButton={(props.sectionIdx === 0 && translationIdx === 0)?true:false}
					{...props}
				/>

			)}

		</div>
	);

}

export default SectionDefault;