import { useEffect, Fragment } from "react";
import Slider from '../Slider';
import Media from '../Media';



function SectionMedia( props ){

	useEffect(() => {
		//
	},[props.section])

	return (
		<div className={`section section--${props.section.display_type || 'media'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >
			{props.section.translations.map( (translation,translationIdx) => {
				return <Fragment key={`section_trans_${translationIdx}`}>

					<div className="section__tab section__media-head"
						key={`translation_${translationIdx}_head`}>
						{ translation.title !== '' && 
							<h2  id={translation.slug}>
								{translation.title}
							</h2>
						}
					</div>

					<Slider 
						{...props}
						type="horizontal">
						{ translation.media.length > 0 && translation.media.map( (media, mediaIdx) => 
							<div className="slider__item"
								key={`media_${translationIdx}_${mediaIdx}`} 
							>
								<small className="slider__item-count">{`${mediaIdx+1}/${translation.media.length}`}</small>
								<Media media={media} />
							</div>
						)}
					</Slider>

				</Fragment>
			})}
		</div>
	);

}

export default SectionMedia;