import { Fragment, useEffect, useState, useRef } from "react";
import Media from '../Media';
import Lightbox from '../Lightbox';
import { translations } from "../../Helpers/Lang";
// import { IoMdCloseCircle, IoIosInformationCircle } from "react-icons/io";


function ArticleModal ( props ){

    const itemsRef = useRef({});

    const [modalIsOpen, setModalIsOpen] = useState(false);

	useEffect(() => {
		// 
	},[])

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
        
    }

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''} ${props.article.media.length === 0 && 'article--no-thumb'} `}>

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 1==2 &&
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

			{ props.article.title !== '' && 
				<h2 id={props.article.slug}>
					{props.article.title}
                    { props.article.undertitle !== '' && 
                        <span>{props.article.undertitle}</span>
                    }
				</h2>
			}

            { props.article.subtitle !== '' && 
                <h3>{props.article.subtitle}</h3>
            }

            { !modalIsOpen && 

                <div className="article--article_modal__head">
                    { props.article.media.length > 0 && 
                        <div className="article__media" >
                            <Media 
                                media={props.article.media[0]} 
                                lightbox={false}
                                lazyload={false}
                                cdnParams="&width=400&height=300&func=crop"
                            />
                        </div>
                    }
                    { ( props.article.description !== '' || props.article.media.length > 1 ) && 
                        <button 
                            className={`btn btn--l ${(modalIsOpen)?'btn--close':'btn--more'}`} 
                            onClick={() => (modalIsOpen) ? closeModal() : openModal()}>
                            {/* (modalIsOpen) 
                                ? <IoMdCloseCircle /> 
                                : <IoIosInformationCircle />
                            */}
                            { (modalIsOpen) 
                                ? (props.lang !== undefined ) ? translations[props.lang].read_less : ''
                                : (props.lang !== undefined ) ? translations[props.lang].read_more : ''
                            }
                        </button>
                    }
                </div>
            }

                
            

            { modalIsOpen && 
                <Fragment>
                    <div className="modal">
                        <div className="modal__wrapper">

                            {/*
                                <Fragment>
                                { props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
                                    <small className="slider__item-count">
                                        {`${props.articlesIndex}/${props.articlesTotal}`}
                                    </small>
                                }

                                { props.article.title !== '' && 
                                    <h2 id={props.article.slug}>
                                        {props.article.title}
                                    </h2>
                                }

                                { props.article.subtitle !== '' && 
                                    <h3>{props.article.subtitle}</h3>
                                }
                                </Fragment>
                            */}

                            <button 
                                onClick={() => (modalIsOpen) ? closeModal() : openModal()}
                                className={`btn btn--l btn--close`}
                                style={{margin: 0 }}
                            >
                                Zatvori
                            </button>

                            <p dangerouslySetInnerHTML={{__html: props.article.description}} />

                            { props.article.media
                                .filter( (elem,idx) => idx > 0 )
                                .map( (mediaItem, mediaItemIdx) => 
                                <div className="article__media"
                                    ref={el => itemsRef.current[`media_${mediaItemIdx}_${mediaItem.id}`] = el}
                                    key={`article_modal_media_${mediaItemIdx}`}>
                                    <Media 
                                        media={mediaItem}
                                        lightbox={false}
                                        cdnParams="&width=1200&func=crop"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <Lightbox items={itemsRef.current} />

                </Fragment>
            }
			
		</div>
	);

}

export default ArticleModal;