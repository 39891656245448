import {Fragment, useEffect} from "react";



function PageLinks(props){

	useEffect(() => {
		if( document.querySelectorAll(`.link`).length > 0 ){
			document.querySelector(`.link:first-of-type`).classList.add('is-active');
		}
	// eslint-disable-next-line
	},[props.lang])

	useEffect(() => {
		if( document.querySelectorAll(`.link`).length > 0 ){
			document.querySelector(`.link:first-of-type`).classList.add('is-active');
		}
	// eslint-disable-next-line
	},[props.page])

	

	return (
		<Fragment>
			{ props.page !== null && props.page.translations.length > 1 && 
				<div className={`page__links`}>
					{ props.page.translations
						.filter(x => x.use_as_link === "1")
						.map( (translation,translationIdx) => 
						<a 	key={`translation_link_${translationIdx}`} 
								href={`#${translation.slug}`}
								className="link link--section"
							>
							{translation.title}
						</a>
					)}
				</div>
			}
		</Fragment>
	);

}

export default PageLinks;