import { useEffect } from "react";
// import { useDraggable } from "react-use-draggable-scroll";
import Media from '../Media';

function ArticleDescription ( props ){

	
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`}> 

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}
            

			{ props.article.subtitle !== '' && 
				<h3>{props.article.subtitle}</h3>
			}

			{ props.article.media.length > 0 && 
				<div className="article__media">
					{props.article.media.map( (media, mediaIdx) => 
						<Media 
							key={`media_${mediaIdx}`} 
							media={media} 
							cdnParams="&width=1200&func=crop"
						/>
					)}
				</div>
			}
			
			{ props.article.description !== '' && 
				<p dangerouslySetInnerHTML={{__html: props.article.description}} />
			}

			{props.showMoreButton && 
				<button 
					className="btn btn--more" 
					onClick={(e) => {
						let btnElem = e.target;
						btnElem.closest('.section').classList.add('section--expanded');
						btnElem.remove();
					}}
				>
					Prikaži cijeli tekst ...
				</button>
			}
			
		</div>
	);

}

export default ArticleDescription;