import { useEffect } from "react";
import Media from '../Media';



function ArticleTextMediaColumns ( props ){

	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`} >

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

            <div className="article__text">
                { props.article.title !== '' && 
                    <h2 id={props.article.slug}>
                        {props.article.title}
                    </h2>
                }

                { props.article.subtitle !== '' && 
                    <h3>{props.article.subtitle}</h3>
                }

                { props.article.description !== '' && 
                    <p dangerouslySetInnerHTML={{__html: props.article.description}} />
                }

                { props.article.media.length > 0 && 
                    <div className="article__media">
                        {props.article.media
                            .filter((x,idx) => idx > 0)
                            .map( (media, mediaIdx) => 
                            <div 	key={`media_${media.id}`}>
                                <Media 
                                    media={media} 
                                    lightboxTitle={`<strong>${props.article.title}</strong> <small>${media.title}</small>`}
                                    lightbox={false}
                                    cdnParams="&width=1200&func=crop"
                                />

                            </div>
                        )}
                    </div>
                }

            </div>

            <div className="article__media-list">
                { props.article.media.length > 0 && 
                    <div className="article__media">
                        {props.article.media
                        .filter((x,idx) => idx === 0)
                        .map( (media, mediaIdx) => 
                            <Media 
                                key={`media_${media.id}`}
                                media={media} 
                                lightboxTitle={`<strong>${props.article.title}</strong> <small>${media.title}</small>`}
                                lightbox={false}
                                cdnParams="&width=1200&func=crop"
                            />
                        )}
                    </div>
                }
            </div>
			
		</div>
	);

}

export default ArticleTextMediaColumns;