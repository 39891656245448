import {Fragment, useEffect, useState, useRef } from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { scrollIntoView } from "seamless-scroll-polyfill";
import { useDraggable } from "react-use-draggable-scroll";
import { apiUrl } from '../Helpers/General';
import { translations } from '../Helpers/Lang';
import { pageTransition } from '../Helpers/Func';

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import SeasonItem from '../Components/SeasonItem';
import Footer from '../Components/Footer';
// import Media from '../Components/Media';


let scrollTimeout = null;
// let headBg = 0;



function Home(props){

	const itemsRef = useRef();
	const navigationRef = useRef();

	const { events } = useDraggable(
		itemsRef,
		{
			applyRubberBandEffect: true,
			safeDisplacement: 100,
			isMounted: true
		}
	);

	const [data, setData] = useState([]);
	const [headInterval, setHeadInterval] = useState([]);

	const [canPrev, setCanPrev] = useState(false);
	const [canNext, setCanNext] = useState(false);
	
	const navigate = useNavigate();
	


	useEffect(() => {

		let itemsElem = itemsRef.current;

		if( itemsElem !== null ){
			

			itemsElem.addEventListener('scroll', (e) => {
				if( scrollTimeout !== null ){
					clearTimeout( scrollTimeout );
					scrollTimeout = null;
				}

				let itemToGo = null;

				//scrollTimeout = setTimeout(() => {
					// find index of element to scroll to	
					let initialDiff = 999999999;
					let itemsLeft = itemsElem.getBoundingClientRect().left;
					let items = [...itemsElem.querySelectorAll(':scope > div')];
					items.forEach( (item, itemIdx) => {
						let itemLeft = item.getBoundingClientRect().left - itemsLeft + 1;
						// let itemRight = item.getBoundingClientRect().right;
						let itemWidth = item.getBoundingClientRect().width;
						if( Math.abs(itemLeft) < initialDiff ){
							initialDiff = Math.abs(itemLeft);
							itemToGo = itemIdx;
						}else if( Math.abs(itemLeft + itemWidth) < initialDiff ){
							initialDiff = Math.abs( itemLeft + itemWidth );
							itemToGo = itemIdx;
						}	
						highlightItem(itemToGo);
					});
				//}, 10);

				scrollTimeout = setTimeout(() => {
					setCanPrev( itemToGo === 0 ? false : true );
					setCanNext( itemToGo === data.length-1 ? false : true );
				}, 300);
				
			}, false);

			setTimeout(()=> {
				setCanNext(true);
			},500);

			return () => {
				if( itemsElem !== null ){
					itemsElem.scrollLeft = 0;
				}
				clearInterval( headInterval );
				setHeadInterval( null );
				clearTimeout( scrollTimeout );
				scrollTimeout = null;
				// headBg = 0;
			}
		}
	// eslint-disable-next-line
	}, [navigationRef.current])

	useEffect(() => {
		pageTransition();
		window.scrollTo(0, 0);

		if( props.lang !== undefined && props.lang !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/lists`)
				.then(res => res.data)
				.then(data => {
					setData(data)
				});
		}
	// eslint-disable-next-line
	},[props.lang, window.location.hash])



	const setPage = (page) => {
		navigate( `${process.env.PUBLIC_URL}/${props.lang}/${page}` );
		window.scrollTo(window.scrollX, 0);
	}

	const highlightItem = ( itemIdx ) => {
		let itemNavi = navigationRef.current.querySelector(`:scope > span:nth-of-type(${itemIdx+1})`);
		if( itemNavi !== null ){
			[...navigationRef.current.querySelectorAll('.active')].forEach( item => {
				item.classList.remove('active');
			});
			itemNavi.classList.add('active');
		}
	}

	const scrollToItem = ( itemIdx ) => {
		setCanPrev( itemIdx === 0 ? false : true );
		setCanNext( itemIdx === data.length-1 ? false : true );

		let item = itemsRef.current.querySelector(`:scope > div:nth-of-type(${itemIdx+1})`);
		if( item !== null ){
			scrollIntoView(item, {behavior: "smooth", block: "bottom", inline: "start" });
		}
	}

	const goToPrev = () => {
		let activeElem = navigationRef.current.querySelector('.hometripple__content-navigation-item.active');
		let activeElemIdx = [...navigationRef.current.querySelectorAll('.hometripple__content-navigation-item')].indexOf(activeElem);
		scrollToItem( activeElemIdx > 0 ? activeElemIdx-1 : 0 );
	}

	const goToNext = () => {
		let activeElem = navigationRef.current.querySelector('.hometripple__content-navigation-item.active');
		let activeElemIdx = [...navigationRef.current.querySelectorAll('.hometripple__content-navigation-item')].indexOf(activeElem);
		scrollToItem( activeElemIdx < data.length-1 ? activeElemIdx+1 : data.length-1 );
	}



	return (
		<Fragment>

			<Helmet>
				<title>{translations[props.lang].title}</title>
			</Helmet>
			
			<div className="page page--hometripple" >

				<div className="hometripple">

					<div className="hometripple__links">

						<div className="hometripple__links-head">
							<h1>{translations[props.lang].title}</h1>
							{/* <canvas className="hometripple__links-head-canvas"></canvas> */}
						</div>

						<div className="hometripple__links-menu">
							<div className="hometripple__links-pages">
								{ props.menu !== undefined && props.menu.length > 0 && 
									props.menu.map( (menuLink, pageIdx) => {
										return <Fragment key={`menu_page_${pageIdx}`}>
											<button
												className={`hometripple__links-item ${(menuLink.slug === '')?'active':''} `}
												onClick={ () => { setPage(menuLink.slug); }}
											>
												{menuLink.title}
											</button>
										</Fragment>
									})
								}
							</div>

						</div>
					</div>

					<div className="hometripple__content">
						
						<div className="hometripple__content-items" ref={itemsRef} {...events}>
							{ data.length > 0 && 
								<Fragment>
									{data.map( (season, seasonIdx) => 
										<SeasonItem 
											key={`home_season_${seasonIdx}`} 
											season={season} 
											classPrefix="hometripple"
											lang={props.lang}
										/>
									)}
								</Fragment>
							}
						</div>

						<div className="hometripple__content-navigation" ref={navigationRef}>
							{ data.map( (seasonObj, seasonIdx) => 
								<span 
									key={`menu_homepage_navigation_${seasonObj.id}`}
									className={`hometripple__content-navigation-item ${(seasonIdx === 0)?'active':''}`}
									onClick={() => {scrollToItem(seasonIdx)}}
									onMouseEnter={() => {
										
									}}
									onMouseLeave={() => {
										
									}}
								/>
							)}	
						</div>

						<div className="hometripple__content-prevnext">
							<button
								className={`hometripple__content-prevnext-prev ${(canPrev ? '' : 'disabled')}`} 
								onClick={goToPrev}>
								<IoIosArrowBack width={32} height={32} />
							</button>
							<button 
								className={`hometripple__content-prevnext-next ${(canNext ? '' : 'disabled')}`}
								onClick={goToNext}>
								<IoIosArrowForward width={32} height={32} />
							</button>
						</div>

					</div>

				</div>

				<Footer  {...props}  />

			</div>
		
		</Fragment>
	);

}

export default Home;