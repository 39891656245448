import { useEffect } from "react";
import Slider from '../Slider';

import ArticlePropagator from "../ArticlePropagator";


function SectionHeadHorizontal( props ){

	useEffect(() => {
		// 
	},[props.section])

	return (
		<div className={`section section--${props.section.display_type || 'head_horizontal'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

			{props.section.translations.length > 0 && 
				<ArticlePropagator
					key={`article_${props.section.translations[0].id}`}
					translation={props.section.translations[0]}
					addClass=""
					{...props}
				/>
			}

			<Slider
				{...props}
				type="horizontal">
				{props.section.translations
					.filter( (x, transIdx) => transIdx > 0)
					.map( (translation,translationIdx) => 
						<ArticlePropagator
							key={`article_${translation.id}`}
							translation={translation}
							articlesIndex={translationIdx+1}
							articlesTotal={props.section.translations.filter( (x, transIdx) => transIdx > 0).length}
							addClass="slider__item"
							{...props}
						/>
				)}
			</Slider>

		</div>
	);

}

export default SectionHeadHorizontal;