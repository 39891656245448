import { Fragment, useEffect, useState, useRef } from "react";
import history from '../../history';
import ArticlePropagator from "../ArticlePropagator";
import { useSwipeable } from "react-swipeable";
import Media from '../Media';
import Lightbox from '../Lightbox';
import { translations } from '../../Helpers/Lang';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


let selectElemTopOffset = 50;


function SectionPresscuts ( props ){

    const galleryRef = useRef();
    const itemsRef = useRef({});

	const [articleSelected, setArticleSelected] = useState(null);
    const [articlesModal, setArticlesModal] = useState(false);


    const [canPrev, setCanPrev] = useState(false);
    const [canNext, setCanNext] = useState(false);

    const handlers = useSwipeable({
		onSwipedLeft: () => {
            selectNext();
		},
		onSwipedRight: () => {
			selectPrevious();
		}
	});



    useEffect(() => {
        window.addEventListener('popstate', checkHash);

        return () => {
            window.removeEventListener('popstate', checkHash);
        };
    });

	useEffect(() => {
        checkHash();
        checkPrevNext();
    // eslint-disable-next-line
	},[props.section, galleryRef.current])

    useEffect(() => {
        checkPrevNext();
		if( articleSelected !== null && galleryRef.current !== undefined && selectElemTopOffset === 0 ){
            selectElemTopOffset = galleryRef.current.offsetTop;
        }
    // eslint-disable-next-line
	},[articleSelected])



    const checkHash = () => {
        let hash = window.location.hash.replace('#', '');
        if( hash !== '' ){
            let selectedArticle = props.section.translations.find( x => x.slug === hash);
            setArticleSelected(selectedArticle);
        }else{
            setArticleSelected(null);
        }
    // eslint-disable-next-line
    }

    const selectArticle = (articleId) => {
        setArticlesModal(false);
        setArticleSelected({
            title: '',
            subtitle: '',
            undertitle: '',
            description: '',
            media: [],
            loading: true,

        });

        // setTimeout(() => {
            let selectedArticle = props.section.translations.find( x => x.id === articleId);
            setArticleSelected(selectedArticle);

            let urlSegments = window.location.href.split('/');
		    if( urlSegments[4] !== undefined ){
                let redirectUrl = `/${props.lang}/${urlSegments[4]}/#${selectedArticle.slug}`;
                history.push(redirectUrl);
                window.location.hash = `#${selectedArticle.slug}`;
            }

            window.scrollTo(0, 0);
        // }, 250);
    }

    const unselectArticle = () => {
        setArticleSelected(null);
        let urlSegments = window.location.href.split('/');
        let redirectUrl = `/${props.lang}/${urlSegments[4]}`;
        history.push(redirectUrl);
        window.location.hash = `#`;
    }

    const selectPrevious = () => {
        let items = props.section.translations.filter( (x, transIdx) => transIdx > 0);
        let activeIdx = items.findIndex(x => x.id === articleSelected.id);

        if( activeIdx > 0 ){
            selectArticle( items[activeIdx - 1].id );
        }
    }

    const selectNext = () => {
        let items = props.section.translations.filter( (x, transIdx) => transIdx > 0);
        let activeIdx = (articleSelected !== null)
            ? items.findIndex(x => x.id === articleSelected.id)
            : -1;

        if( activeIdx < items.length - 1 ){
            selectArticle( items[activeIdx + 1].id );
        }
    }

    const checkPrevNext = () => {
        let items = props.section.translations.filter( (x, transIdx) => transIdx > 0);
        let activeIdx = items.findIndex(x => articleSelected !== null && x.id === articleSelected.id);

        setCanPrev( 
            (activeIdx > 0) 
                ? true 
                : false 
        );

        setCanNext( 
            (activeIdx < items.length - 1) 
                ? true 
                : false 
        );
    }



    // content vars

    const contentText = ( 
        articleSelected !== null 
        && articleSelected.description !== '' 
    ) 
        ? articleSelected.description
        : null;

    const contentImages = ( 
            articleSelected !== null
            && articleSelected.media.length > 0
            && articleSelected.media.filter( x => 
                x.path.toLowerCase().includes('.jpg') 
                || x.path.toLowerCase().includes('.png') 
                || x.path.toLowerCase().includes('.gif') 
            ).length > 0
        )
            ? articleSelected.media.filter( x => 
                x.path.toLowerCase().includes('.jpg') 
                || x.path.toLowerCase().includes('.png') 
                || x.path.toLowerCase().includes('.gif') 
            )
            : null;

    const contentDownloads = ( 
        articleSelected !== null
        && articleSelected.media.length > 0
        && articleSelected.media.filter( x => 
            x.path.toLowerCase().includes('.pdf') 
            || x.path.toLowerCase().includes('.doc') 
            || x.path.toLowerCase().includes('.docx') 
            || x.path.toLowerCase().includes('.zip') 
            || x.path.toLowerCase().includes('.rar') 
            || x.path.toLowerCase().includes('.ppt') 
        ).length > 0
    )
        ? articleSelected.media.filter( x => 
            x.path.toLowerCase().includes('.pdf') 
            || x.path.toLowerCase().includes('.doc') 
            || x.path.toLowerCase().includes('.docx') 
            || x.path.toLowerCase().includes('.zip') 
            || x.path.toLowerCase().includes('.rar') 
            || x.path.toLowerCase().includes('.ppt') 
        )
        : null;

    const contentVideos = ( 
        articleSelected !== null
        && articleSelected.media.length > 0
        && articleSelected.media.filter( x => x.embed !== '' ).length > 0
    )
        ? articleSelected.media.filter( x => x.embed !== '' )
        : null;

    const contentAudios = ( 
        articleSelected !== null
        && articleSelected.media.length > 0
        && articleSelected.media.filter( x => 
            x.path.toLowerCase().includes('.wav') 
            || x.path.toLowerCase().includes('.mp3') 
        ).length > 0
    )
        ? articleSelected.media.filter( x => 
            x.path.toLowerCase().includes('.wav') 
            || x.path.toLowerCase().includes('.mp3') 
        )
        : null;
    


	return (
		<div className={`section section--${props.section.display_type || 'default'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

            <ArticlePropagator
                key={`article_head`}
                translation={props.section.translations[0]}
                addClass="slider__item"
                {...props}
            />

                
                
            <div 
                className="articles-menu" 
                ref={galleryRef}
                {...handlers}
            >
                { props.lang !== undefined && 
                    <Fragment>

                        { articleSelected === null &&
                            <div className="articles-menu__list">
                                {props.section.translations
                                    .filter( (x, transIdx) => transIdx > 0)
                                    .map( (translation,translationIdx) => 
                                    <button
                                        key={`gallery_${translationIdx}`} 
                                        className={`gallery-modal__item ${(articleSelected !== null && articleSelected.id === translation.id)?'active':''}`}
                                        onClick={() => {selectArticle(translation.id)}}
                                    >
                                        {translation.title} 
                                        <small>{translation.undertitle}</small>
                                    </button>
                                )}
                            </div>
                        }

                        { articleSelected !== null &&
                            <div className="articles-menu__detail">
                                <span className="articles-menu__detail-item articles-menu__detail-item--accent">
                                    {articleSelected.title}<br />
                                    <small>{articleSelected.undertitle}</small>
                                </span>
                                <button 
                                    onClick={() => selectPrevious()}
                                    className={`btn gallery-selected__navi ${(canPrev === false)?'disabled btn--disabled':''}`}
                                ><IoIosArrowBack /></button>
                                <button 
                                    className={`btn gallery-selected__navi ${(canNext === false)?'disabled btn--disabled':''}`}
                                    onClick={() => selectNext()}
                                ><IoIosArrowForward /></button>
                                <button 
                                    className={`btn gallery-selected__navi ${(canNext === false)?'disabled btn--disabled':''}`}
                                    onClick={() => unselectArticle()}
                                >x</button>
                            </div>
                        }

                    </Fragment>
                }
                { articlesModal === true && 
                    <div className="gallery-modal">
                        <div className="gallery-modal__wrapper">
                            {props.section.translations
                                .filter( (x, transIdx) => transIdx > 0)
                                .map( (translation,translationIdx) => 
                                
                                <div 
                                    key={`gallery_${translationIdx}`} 
                                    className={`gallery-modal__item ${(articleSelected !== null && articleSelected.id === translation.id)?'active':''}`}
                                    onClick={() => {selectArticle(translation.id)}}>
                                    {translation.title}
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>

            { articleSelected !== null && articleSelected.hasOwnProperty('loading') && articleSelected.loading === true &&  
                <span>{translations[props.lang].loading}</span>
            }

            { articleSelected !== null && 
                <Fragment>

                    <div className="modal">
                        <div className="modal__wrapper">
                        
                            <h3>{articleSelected.subtitle}</h3>

                            <div className="modal__wrapper-content">

                                { contentText !== null && 
                                    <div className="modal__wrapper-content--text">
                                        <h3>Tisak</h3>

                                        { contentDownloads !== null && 
                                            <div>
                                                { contentDownloads.map( (translationMedia,translationMediaIdx) => 
                                                    <div 
                                                        key={`gallery_media_${translationMediaIdx}`}
                                                        className="modal__wrapper-media">
                                                        <Media 
                                                            media={translationMedia}
                                                            lightbox={false}
                                                            cdnParams="&width=1200&func=crop"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        }

                                        <p dangerouslySetInnerHTML={{__html: contentText}} />
                                    </div>
                                }

                                { contentImages !== null && 
                                    <div className="modal__wrapper-content--downloads">
                                        <h3>Docs</h3>
                                        { contentImages.map( (translationMedia,translationMediaIdx) => 
                                            <div 
                                                key={`gallery_media_${translationMediaIdx}`}
                                                ref={el => itemsRef.current[`media_${translationMediaIdx}_${translationMedia.id}`] = el}
                                                className="modal__wrapper-media">
                                                <Media 
                                                    media={translationMedia}
                                                    cdnParams="&width=800&func=crop"
                                                />
                                            </div>
                                        )}
                                    </div>
                                }

                                {/* contentDownloads !== null && 
                                    <div className="modal__wrapper-content--downloads">
                                        <h3>Downloads</h3>
                                        { contentDownloads.map( (translationMedia,translationMediaIdx) => 
                                            <div 
                                                key={`gallery_media_${translationMediaIdx}`}
                                                className="modal__wrapper-media">
                                                <Media 
                                                    media={translationMedia}
                                                    lightbox={false}
                                                    cdnParams="&width=1200&func=crop"
                                                />
                                            </div>
                                        )}
                                    </div>
                                */}

                                { contentVideos !== null && 
                                    <div className="modal__wrapper-content--videos">
                                        <h3>Video</h3>
                                        { contentVideos.map( (translationMedia,translationMediaIdx) => 
                                            <div 
                                                key={`gallery_video_${translationMediaIdx}`}
                                                className="modal__wrapper-media">
                                                <Media 
                                                    media={translationMedia}
                                                    lightbox={false}
                                                    cdnParams="&width=1200&func=crop"
                                                />
                                            </div>
                                        )}
                                    </div>
                                }

                                { contentAudios !== null && 
                                    <div className="modal__wrapper-content--audios">
                                        <h3>Audio</h3>
                                        { contentAudios.map( (translationMedia,translationMediaIdx) => 
                                            <div 
                                                key={`gallery_media_${translationMediaIdx}`}
                                                className="modal__wrapper-media">
                                                <Media 
                                                    media={translationMedia}
                                                    lightbox={false}
                                                    cdnParams="&width=1200&func=crop"
                                                />
                                            </div>
                                        )}
                                    </div>
                                }

                            </div>

                        </div>
                    </div>

                    <Lightbox items={itemsRef.current} />

                </Fragment>
            }

		</div>
	);

}

export default SectionPresscuts;