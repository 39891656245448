import { useEffect, useState } from "react";
// import { useSwipeable } from "react-swipeable";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { IoIosArrowDown } from "react-icons/io";

import SeasonItem from '../SeasonItem';


function SectionHead( props ){

	const [pageScrollIcon, setPageScrollIcon] = useState(true);

	useEffect(() => {

		document.addEventListener('scroll', (e) => {
			// bottom links border
			if( window.scrollY < 50 ){
				setPageScrollIcon(true);
			}else{
				setPageScrollIcon(false);
			}

		}, false);
	}, [])

	/*
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	const handlers = useSwipeable({
		onSwipedRight: (e) => { 
			props.goToPrev();
		},
		onSwipedLeft: (e) => { 
			props.goToNext();
		},
	});
	*/

	const scrollToContent = () => {
		const elemContent = document.querySelector('.page__content');
		if( elemContent !== null ){
			scrollIntoView(elemContent.querySelector('.article:first-of-type'), {behavior: "smooth", block: "center", inline: 'start' });
		}
	}


	return (
		<div className="page__head" >
			{ props.season !== null && props.season.translations !== undefined && props.season.translations.length > 0 &&  
				<div className={`page__head-wrapper`}>

					<SeasonItem 
						key={`goto_season`} 
						season={props.season}
						classPrefix={'hometripple'}
						mediaParams={`&force_format=png&width=600&height=600&func=crop`} 
						lang={props.lang}
					/>

					{/*

					<div className="page__head-content">
						<h1 className="fx fx--faded" id="top">{props.season.translations[0].title}</h1>
						<h3 className="fx fx--faded">{props.season.translations[0].subtitle}</h3>
						<p className="fx fx--faded" dangerouslySetInnerHTML={{__html: props.season.translations[0].description}}></p>
					</div>
					
					{window.innerWidth <= 992 && props.season.translations[0].media.length > 0 && 1==2 &&
						<div className="page__head-media page__head-media--cursor fx fx--faded">
							<Media key={`media_season`} media={props.season.translations[0].media[0]} />
						</div>
					}
					{ window.innerWidth > 1 && 
						<div className="page__head-media fx fx--faded" >
							{ props.season.translations[0].media.length === 1 && 
								<Media key={`media_season`} media={props.season.translations[0].media[0]} />
							}
							{ props.season.translations[0].media.length > 1 && 
								<Media key={`media_season`} media={props.season.translations[0].media[1]} />
							}
							</div>
					}
					*/}

					
					<div 
						onClick={() => { scrollToContent()}}
						className={`page__head-scroll-icon ${(pageScrollIcon === true)?'':'hidden'}`} 
					>
						<a href="#top"><IoIosArrowDown /></a>
					</div>
					

				</div>
			}
		</div>
	)
}

export default SectionHead;