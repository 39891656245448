import { Fragment, useEffect, useState } from "react";


import SectionDefault from 						'./Sections/SectionDefault';
import SectionMedia from 						'./Sections/SectionMedia';
import SectionHorizontal from 					'./Sections/SectionHorizontal';
import SectionHeadHorizontal from 				'./Sections/SectionHeadHorizontal';
import SectionVerticalMediaHorizontal from 		'./Sections/SectionVerticalMediaHorizontal';
import SectionGallery from 						"./Sections/SectionGallery";
import SectionPresscuts from 					"./Sections/SectionPresscuts";
import SectionOther from 						"./Sections/SectionOther";


function SectionPropagator( props ){

	const [component, setComponent] = useState(null);

	useEffect(() => {

		const sections = {
			default: 							SectionDefault,
			media: 								SectionMedia,
			horizontal:							SectionHorizontal,
			dts_grid1:							SectionHeadHorizontal,
			vertical_media_horizontal:			SectionVerticalMediaHorizontal,
			dtp_grid4:							SectionGallery,
			dtp_grid5:							SectionPresscuts,
			dtp_grid6:							SectionOther,
		};

		let Component = sections[ props.section.display_type ] || SectionDefault;
		setComponent( 
			<Component 
				key={`comp_${Math.random()}`} 
				section={props.section} 
				sectionIdx={props.sectionIdx} 
				{...props}
			/> 
		);

	// eslint-disable-next-line
	}, [props.section])

	return (
		<Fragment>
		
			{ component !== null && {...component} }

		</Fragment>
	);

}

export default SectionPropagator;