import { Fragment, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import _ from "lodash";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
// import ReactTextTransition from "react-text-transition";
import { translations } from '../Helpers/Lang';
import { apiUrl } from '../Helpers/General';
import { pageTransition } from '../Helpers/Func';
import { RiCloseFill } from "react-icons/ri";

import Footer from '../Components/Footer';



function Search(props){

    const inputRef = useRef();

	const [data, setData] = useState({});
    const [dataOriginal, setDataOriginal] = useState([]);
	const [dataFilter, setDataFilter] = useState('');
    const [dataQuery, setDataQuery] = useState('');
	const [isLoading, setIsLoading] = useState(false);


	useEffect(() => {

		pageTransition();
		
		if( props.lang !== undefined && props.lang !== '' ){
			inputRef.current.focus();
		}
        window.addEventListener('keyup', listenEnter);

		return () => {
			setData({});
            window.removeEventListener('keyup', listenEnter);
		}
	// eslint-disable-next-line
	},[props.lang])

    useEffect(() => {
        if( dataOriginal.length > 0 ){
            doHighlight();
        }
    // eslint-disable-next-line
    }, [data, dataQuery])

    const listenEnter = (e) => {
        // console.log(e);
        if( e.keyCode === 13 ){
            doSearch();
        }
        if( e.keyCode === 27 ){
            doReset();
        }
    }

    const doSearch = () => {
        setDataFilter(inputRef.current.value);
        setIsLoading(true);
        axios.get(`${apiUrl}${props.lang}/api/search?q=${inputRef.current.value}`)
            .then(res => res.data)
            .then(resData => {
                let sortedData = (resData.length > 0) ? resData.sort( (a,b) => a.title.localeCompare(b.title)) : [];
                let groupedData = _.groupBy(sortedData, item => item.url);
                setData( groupedData );
                setDataQuery( (resData.length > 0) ? resData[0].query : '');
                setDataOriginal(resData);
                setIsLoading(false);
                // window.scrollTo(0, 0);
            })
            .catch( err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const doReset = () => {
        setDataFilter('');
        inputRef.current.value = '';
        removeHiglight();
    }

    const doHighlight = () => {
        let queryElements = document.querySelectorAll('.search-results__item');
        
        const regex = new RegExp(dataQuery, 'gi');

        [...queryElements].forEach( elem => {
            let text = elem.innerHTML;
            text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
            const newText = text.replace(regex, '<mark class="highlight">$&</mark>');
            elem.innerHTML = newText;
        });
    }

    const removeHiglight = () => {
        let queryElements = [...document.querySelectorAll('.highlight')];
        queryElements.forEach( queryElem => queryElem.classList.remove('highlight'));
        inputRef.current.focus();
    }

	return (
		<Fragment>

			<Helmet>
				<title>{translations[props.lang].title_short} - {translations[props.lang].search}</title>
			</Helmet>

			<div className="page page--search">
			
				{ translations[props.lang] !== undefined && 
					<Fragment>
						
						<div className="search-head">

							<h1>
							    {translations[props.lang].search}
							</h1>

                            <span className={`search__form ${(isLoading === false && dataFilter !== '') ? 'active' : ''}`}>

                                <input 
                                    ref={inputRef}
                                    autoFocus
                                    placeholder={translations[props.lang].search_placeholder} 
                                    type="text" />

                                <button 
                                    className={`btn--reset`}
                                    onClick={doReset}
                                >
                                    <RiCloseFill />
                                </button>

                                <button 
                                    className={'btn--search'} 
                                    onClick={doSearch}
                                >
                                    {translations[props.lang].search_do}
                                </button>

                            </span>

						</div>

                        <div className="search-results">

                            { isLoading === true && 
                                <span>{translations[props.lang].loading}</span>
                            }

                            { isLoading === false && dataOriginal.length === 0 && dataFilter !== '' && 
                                <span>{translations[props.lang].search_no_results}</span>
                            }

                            { isLoading === false && dataOriginal.length > 0 && 
                                <Fragment>

                                    <div className="search-results__count">
                                        <div>
                                            {Object.keys(data).length}&nbsp;
                                            { (Object.keys(data).length > 1) 
                                                ? translations[props.lang].search_count_results 
                                                : translations[props.lang].search_count_result 
                                            }
                                        </div>
                                        <div>
                                            {translations[props.lang].label_articles}
                                        </div>
                                    </div>

                                    { Object.entries(data).map( ([groupUrl, groupItems], groupIdx) => 
                                        <div key={`result_${groupIdx}`}
                                             className="search-results__item">
                                            <div>
                                                <small>{groupIdx+1}/{Object.keys(data).length}</small>
                                                { (groupUrl === `` || groupUrl === `/` || groupUrl === `${props.lang}/`) 
                                                    ? <Fragment>
                                                            <h2>{translations[props.lang].seasons}</h2>
                                                            <h3>{translations[props.lang].title_short}</h3>
                                                        </Fragment>
                                                    : <Fragment>
                                                        <h2>{groupItems[0].title}</h2>
                                                        <h3>{groupItems[0].subtitle}</h3>
                                                    </Fragment>
                                                }
                                                <NavLink className="" to={`/${groupUrl}`}>
                                                    {translations[props.lang].search_read_more}
                                                </NavLink>
                                            </div>
                                            <div>
                                                { groupItems.map( (item, itemIdx) =>
                                                    <div key={`result_item_${itemIdx}`}
                                                        className="search-results__item-article">
                                                        { item.title_content !== '' && <h4>{item.title_content}</h4>}
                                                        { groupUrl === `${props.lang}/` && item.subtitle !== '' && <h5>{item.subtitle}</h5>}
                                                        {<p dangerouslySetInnerHTML={{__html: item.description_short}} />}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            }

                        </div>

					</Fragment>
				}

			</div>



			<Footer {...props} />

		</Fragment>
	);

}

export default Search;