import { Fragment, useState, useEffect, useRef} from "react";
import { useSwipeable } from "react-swipeable";
import { IoMdArrowDropleft, IoMdArrowDropright, IoIosClose } from "react-icons/io";
// import Magnifier from "react-magnifier";



function Lightbox( props ){

	const modalRef = useRef();
	const contentRef = useRef();
	const contentCopyRef = useRef();

	const [allItems, 		setAllItems] = useState([]);
	const [lightboxItems, 	setLightboxItems] = useState([]);
	const [selectedIdx,		setSelectedIdx] = useState(null);
	const [selectedSrc,		setSelectedSrc] = useState(null);
	const [previousSrc,		setPreviousSrc] = useState(null);
	const [selectedTitle,	setSelectedTitle] = useState(null);

	// const [zoomLevel,		setZoomLevel] = useState(1);
	// const [zoomSize,		setZoomSize] = useState(200);

	const [enabledPrev,		setEnabledPrev] = useState(false);
	const [enabledNext,		setEnabledNext] = useState(false);



	const handlers = useSwipeable({
		/*
		onSwiping: (e) => {
			let transitionDelta = e.deltaY + .25;
			if( modalRef !== undefined ){
				modalRef.current.querySelector('lightbox__content').style.transform = `translateX(${transitionDelta}px)`;
			}
		},
		onSwiped: (e) => {
			if( modalRef !== undefined ){
				modalRef.current.querySelector('lightbox__content').style.transform = `translateX(0px)`;
			}
		},
		*/
		onSwipedRight: () => {
			goPrev();
		},
		onSwipedLeft: () => {
			goNext();
		}
	});



	useEffect(() => {
		if( props.items.length !== allItems.length ){
			setAllItems( 
				props.items
			);
		}
	// eslint-disable-next-line
	}, [props.items])

	useEffect(() => {
		setLightboxItems(
			Object.values(allItems)
				.filter( x => x.querySelector('[data-lightbox]') !== null )
		)
	}, [allItems])

	useEffect(() => {
		lightboxItems.forEach( (lbItem, lbItemIdx) => {
				lbItem.querySelector('[data-lightbox]').addEventListener('click', e => {
					e.preventDefault();
					setSelectedIdx(lbItemIdx);
				})
			});
			
	}, [lightboxItems]);

	useEffect(() => {
		if( lightboxItems.length > 0 && selectedIdx !== null ){
			setSelectedSrc(
				lightboxItems[selectedIdx].querySelector('[data-lightbox]').dataset.lightboxSrc
			)
			setSelectedTitle(
				lightboxItems[selectedIdx].querySelector('[data-lightbox]').dataset.lightboxTitle
			)
			document.querySelector('body').classList.add('is-modal');

			setEnabledPrev( (selectedIdx > 0) ? true : false );
			setEnabledNext( (selectedIdx < lightboxItems.length-1) ? true : false );

			modalRef.current.addEventListener('mousemove', checkACtivity)

		}else{
			document.querySelector('body').classList.remove('is-modal');
		}
	// eslint-disable-next-line
	}, [selectedIdx]);

	useEffect(() => {
		setTimeout(() => {
			setPreviousSrc(null);
		}, 300);
		setTimeout(() => {
			if( contentCopyRef.current !== null && contentCopyRef.current !== undefined ){
				contentCopyRef.current.classList.remove('fade', 'fade--left', 'fade--right');
			}
			if( contentRef.current !== null && contentRef.current !== undefined ){
				contentRef.current.classList.remove('fade', 'fade--left', 'fade--right');
			}
		}, 400);
		setTimeout(() => {
			setPreviousSrc(selectedSrc);
		}, 1000);
	}, [selectedSrc]);


	const checkACtivity = (e) => {
		
	}

	const goPrev = () => {
		let prevIndex = ( selectedIdx > 0 ) ? selectedIdx - 1 : 0;
		doIndexTransition(prevIndex, -1);
	}

	const goNext = () => {
		let nextIndex = ( selectedIdx < lightboxItems.length-1 ) ? selectedIdx + 1 : selectedIdx;
		doIndexTransition(nextIndex, +1);
	}

	const doIndexTransition = ( idx, delta ) => {
		setSelectedSrc(null);
		if( contentCopyRef.current !== undefined ){
			contentCopyRef.current.classList.add('fade', (delta > 0) ? 'fade--right' : 'fade--left');
		}
		setTimeout(() => {
			setSelectedIdx(idx);
			if( contentRef.current !== undefined ){
				contentRef.current.classList.add('fade', (delta > 0) ? 'fade--right' : 'fade--left');
			}
		}, 100);
	}

	const closeModal = () => {

		if( modalRef.current !== null ){
			modalRef.current.removeEventListener('mousemove', checkACtivity);
		}

		setSelectedIdx(null);
		setSelectedSrc(null);
		setPreviousSrc(null);
	}

	
	return (
		<Fragment>

			{ selectedIdx !== null && 

				<div className="lightbox" {...handlers} ref={modalRef}>

					<div 
						className="lightbox__overlay" 
						onClick={closeModal} 
					/>

					<div className="lightbox__head">

						<div className="lightbox__head-left" dangerouslySetInnerHTML={{__html: selectedTitle}} />

						<div className="lightbox__head-center">
							{ selectedIdx + 1} / {lightboxItems.length}
						</div>

						<div className="lightbox__head-right">
							<button onClick={closeModal} >
								<IoIosClose />
							</button>
						</div>

					</div> 



					{ enabledPrev === true && 
						<button className="lightbox__navigation-prev" onClick={goPrev}>
							<IoMdArrowDropleft />
						</button>
					}

					{ enabledNext === true && 
						<button className="lightbox__navigation-next" onClick={goNext}>
							<IoMdArrowDropright />
						</button>
					}



					<div className="lightbox__content" ref={contentRef}>
						{/*<Magnifier 
							src={selectedSrc} 
							className='lightbox-magnifier' 
							zoomFactor={zoomLevel}
							width={'auto'}
							height={'auto'}
							mgWidth={zoomSize}
							mgHeight={zoomSize}
							mgShape="square"
							mgBorderWidth={0}
						/>*/}
					</div>


					<div className="lightbox__content-copy" ref={contentCopyRef}>
						<img src={previousSrc} alt="" />
					</div>

					
					<div className="lightbox__foot">

						{/*
						<div className="lightbox__foot-center">
							<label>Zoom:</label><input type="range" step=".05" min=".25" max="2.5" onChange={(e) => setZoomLevel(parseFloat(e.target.value))} />
							&nbsp;&nbsp;&nbsp;
							<label>Size:</label><input type="range" step="10" min="200" max="600" onChange={(e) => setZoomSize(parseInt(e.target.value))} />
						</div>
						*/}

					</div> 


				</div>
			}
		</Fragment>
	);

}

export default Lightbox;