import { Fragment, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
// import ReactTextTransition from "react-text-transition";
import ReactTooltip from 'react-tooltip';
import { translations } from '../Helpers/Lang';
import { apiUrl, cdnUrl, cdnParams } from '../Helpers/General';
import { pageTransition } from '../Helpers/Func';

import Footer from '../Components/Footer';


let termCategorisationChar = '';
let termCategorisationDisplay = '';
let termsAlphabet = [];




function Terms(props){

	const [data, setData] = useState({});
	const [dataFilter, setDataFilter] = useState('');
	const [tooltip, setTooltip] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);


	useEffect(() => {

		pageTransition();
		
		if( props.lang !== undefined && props.lang !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/terms`)
				.then(res => res.data)
				.then(resData => {

					let terms = {};
					resData
						.sort( (a,b) => a.term.localeCompare(b.term))
						.forEach( (term, termIdx) => {
							let termProp = term.term.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
							if( terms.hasOwnProperty( termProp ) === false ){
								terms[termProp] = {
									term: 		term.term,
									seasons: 	[]
								};
							}
							terms[termProp].seasons.push( term );
						}
					);

					setData( Object.values(terms) );
					setIsLoaded(true);
					window.scrollTo(0, 0);
				});
		}
		return () => {
			setData({});
		}
	// eslint-disable-next-line
	},[props.lang])


	useEffect(() => {
		termsAlphabet = []
	}, [dataFilter])


	const termsFiltered = Object.values(data)
		.filter(x => x.term.toLowerCase().includes(dataFilter.toLowerCase()));


	return (
		<Fragment>

			<Helmet>
				<title>{translations[props.lang].title_short} - {translations[props.lang].terms}</title>
			</Helmet>

			<div className="page page--terms">
			
				{ translations[props.lang] !== undefined && 
					<Fragment>
						
						<div className="terms-head">
							<h1 id="terms-search">
								
									{translations[props.lang].terms}
								
							</h1>
							<input 
								placeholder={translations[props.lang].terms_placeholder} 
								type="search" 
								onChange={(e) => {
									termCategorisationChar = '';
									termCategorisationDisplay = '';
									setDataFilter( e.target.value);
								}} />
						</div>

						{ isLoaded === false && 
							<span>{translations[props.lang].loading}</span>
						}

						{ isLoaded === true && Object.values(data).length > 0 && 
							<div className="terms">
								{ termsFiltered
									.map( (term, termIdx) => {

										let currentTermCategorisationChar = term.term.slice(0,1);
										if( termCategorisationChar !== currentTermCategorisationChar ){
											termCategorisationChar = currentTermCategorisationChar;
											termCategorisationDisplay = termCategorisationChar;

											if( termsAlphabet.includes(termCategorisationChar) === false ){
												termsAlphabet.push(termCategorisationChar);
											}
										}else{
											termCategorisationDisplay = '';
										}

										return <Fragment key={`term_${termIdx}`}>

											{ termCategorisationDisplay !== '' && 
												<h2 className="term-category" id={`letter-${termCategorisationDisplay}`}>
													{termCategorisationDisplay}
												</h2>
											}

											<div className="term">
												
												
												{ 	term.seasons[0].goto_slug !== '' &&  

													<Fragment>

														<span
															key={`term_link_title_${termIdx}`}
															className={`term__title term__title--link`}>
															<NavLink
																key={`term_link_slug_${termIdx}`}
																className={`term__title`}
																to={`/${props.lang}/${term.seasons[0].goto_slug}`}
																data-term={encodeURIComponent(term.term)}
															>
																{ term.term }
															</NavLink>
														</span>

													</Fragment>
												}



												{ 	term.seasons[0].goto_slug === '' &&  

													<Fragment>

														<span
															key={`term_link_title_${termIdx}`}
															className={`term__title term__title--linkX`}>
															{ term.term }
														</span>

														<div className='term__seasons'>
															{term.seasons
																.sort( (x,y) => parseInt(x.usort) - parseInt(y.usort))
																.map( (season, seasonIdx) => {

																let tooltipHtml = `<div class="tooltip__content">
																	<p>${season.translations[0].title}</p>
																	<p>${season.translations[0].subtitle}</p>
																</div>`;
																if ( season.translations[0].media !== undefined && season.translations[0].media.length > 0 ){
																	let mediaItem = season.translations[0].media[0];
																	tooltipHtml += `<div class="tooltip__media" 
																		style="background-image: url(${cdnUrl}${encodeURIComponent(mediaItem.path)}${cdnParams}&force_format=png&width=200&height=200&func=crop);" 
																	/>`;
																}
																return <NavLink
																	key={`term_link_multi_${termIdx}_${seasonIdx}`}
																	className={`term__season`}
																	to={`/${props.lang}/${season.slug}`}
																	data-term={encodeURIComponent(season.term)}

																	data-tip={tooltipHtml}
																	data-for={`tooltip-terms`}
																	data-html
																	data-multiline
																	data-iscapture
																	onMouseEnter={() => setTooltip(true)}
																	onMouseLeave={() => {
																		setTooltip(false);
																		setTimeout(() => setTooltip(true), 1);
																	}}
																>
																	{ season.translations[0].title }
																</NavLink>
															})}
														</div>
													</Fragment>
												}

											</div>
											
										</Fragment>
									})
								}

								{ tooltip === true && 
									<ReactTooltip  
										className="tooltip"
										type={'light'}
										offset={{top: 20}}
										id={`tooltip-terms`} 
									/>
								}

							</div>
						}
					</Fragment>
				}

				{/* 
				<div className={`page__links`}>

					<span 	key={`translation_link_search`} 
							onClick={() => { window.scrollTo(0, 0); }}
							className="link link--sectionX"
					>
						{translations[props.lang].terms_search}
					</span>

					{ termsAlphabet
						.sort( (a,b) => a.localeCompare(b))
						.map( (letter,letterIdx) => {
						return (
							<a 	key={`translation_link_${letterIdx}`} 
									href={`#letter-${letter}`}
									className="link link--section"
							>
								{letter}
							</a>
						)
					})}
					
				</div>
				*/}

			</div>



			<Footer {...props} />

		</Fragment>
	);

}

export default Terms;