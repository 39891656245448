import { useEffect, useState } from "react";
import { translations } from "../../Helpers/Lang";
import Media from '../Media';



function ArticleModalFull ( props ){

    const [modalIsOpen, setModalIsOpen] = useState(false);

	useEffect(() => {
        // 
	},[props.lang])

    /**
     * this will open modal with all media files for article except first one (thumb)
     */
    const openModal = () => {
        props.doModal(props.article);
        // setModalIsOpen(true);
        // document.querySelector('body').style.overflow = 'hidden';
    }

    const closeModal = () => {
        setModalIsOpen(false);
        document.querySelector('body').style.overflow = '';
    }

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''} ${props.article.media.length === 0 && 'article--no-thumb'} `} >

			{/* props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
            */}

			{ props.article.title !== '' && 
				<h2 id={props.article.slug}>
					{props.article.title}
                    { props.article.undertitle !== '' && 
                        <span>{props.article.undertitle}</span>
                    }
				</h2>
			}

            { props.article.subtitle !== '' && 
                <h3>{props.article.subtitle}</h3>
            }

            { !modalIsOpen && 
                <div className="article--article_modalfull__head">
                    { props.article.media.length > 0 && 
                        <div className="article__media">
                            <Media 
                                media={props.article.media[0]} 
                                lightbox={false}
                                lazyload={false}
                                cdnParams="&width=400&height=300&func=crop"
                            />
                        </div>
                    }
                    { ( props.article.description !== '' || props.article.media.length > 1 ) && 
                        <button 
                            className={`btn btn--l ${(modalIsOpen)?'btn--close':'btn--more'}`} 
                            onClick={() => (modalIsOpen) ? closeModal() : openModal()} >
                            {/* (modalIsOpen) 
                                ? <IoMdCloseCircle /> 
                                : <IoIosInformationCircle />
                            */}
                            { (modalIsOpen) 
                                ? translations[props.lang].read_less
                                : translations[props.lang].read_more
                            }
                        </button>
                    }
                </div>
            }
			
			{/* props.article.description !== '' && 
				<p dangerouslySetInnerHTML={{__html: props.article.description}} />
            */}
			
		</div>
	);

}

export default ArticleModalFull;