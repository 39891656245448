export const pageTransition = () => {

	const transitionIntervalUnit = 300;

	document.querySelector('.main').classList.add('transition');
	document.querySelector('.main').classList.add('transition--content-out');

	setTimeout(() => {
		document.querySelector('.main').classList.add('transition--start');
	}, transitionIntervalUnit * 1);

	setTimeout(() => {
		document.querySelector('.main').classList.remove('transition--start');
		document.querySelector('.main').classList.add('transition--mid');
	}, transitionIntervalUnit * 2);

	setTimeout(() => {
		document.querySelector('.main').classList.remove('transition--mid');
		document.querySelector('.main').classList.add('transition--end');
	}, transitionIntervalUnit * 3.5);

	setTimeout(() => {
		document.querySelector('.main').classList.remove('transition--end');
		document.querySelector('.main').classList.remove('transition');
		document.querySelector('.main').classList.remove('transition--content-out');
		document.querySelector('.main').classList.add('transition--content-in');
	}, transitionIntervalUnit * 5.5);

	setTimeout(() => {
		document.querySelector('.main').classList.remove('transition--content-in');
	}, transitionIntervalUnit * 10);

}

export const isInViewport = (el) => {
	var top = el.offsetTop;
	var left = el.offsetLeft;
	var width = el.offsetWidth;
	var height = el.offsetHeight;

	while(el.offsetParent) {
		el = el.offsetParent;
		top += el.offsetTop;
		left += el.offsetLeft;
	}

	return (
		top < (window.pageYOffset + window.innerHeight) &&
		left < (window.pageXOffset + window.innerWidth) &&
		(top + height) > window.pageYOffset &&
		(left + width) > window.pageXOffset
	);
}


export const goTo404 = (lang, history, navigate) => {
	let redirectUrl = `/${lang}/404`;
	history.push( redirectUrl );
	navigate( redirectUrl );
}