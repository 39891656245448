import {Fragment, useEffect, useState, useRef} from "react";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { scrollIntoView } from "seamless-scroll-polyfill";
import { apiUrl/*, pusherChannel*/ } from '../Helpers/General';
import { translations } from '../Helpers/Lang';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import SeasonItem from '../Components/SeasonItem';

// const pageIntervalTime = 15000;
let pageTimeout = null;

const themes = ['white', 'red', 'black', 'blue'];

function HomeTv(props){

	const itemsRef = useRef();
	const navigationRef = useRef();
	const prevRef = useRef();
	const nextRef = useRef();
	const themeBtnRef = useRef();
	const langBtnRef = useRef();

	const [data, setData] = useState([]);
	const [themeId, setThemeId] = useState();
	const [themeLang, setThemeLang] = useState(props.lang);

	useEffect(() => {
		if( props.lang !== undefined && props.lang !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/lists`)
				.then(res => res.data)
				.then(response => {
					setData(response);
					subscribeToUserEvents();
					detectKeyboardInput();
					themeBtnRef.current.click();

					/*
					pageTimeout = setTimeout(() => {
						nextRef.current.click();
					}, pageIntervalTime);
					*/
				});
		}

		return () => {
			clearTimeout(pageTimeout);
			// document.removeEventListener("keydown", {});
		}
	// eslint-disable-next-line
	},[props.lang, window.location.hash])

	useEffect(() => {
		console.log('set: ', themeId);
	}, [themeId])


	/**
	 * not in use cause we use bluetooth keyboard
	 */
	
	const subscribeToUserEvents = () => {
		/*
		let channel = window.pusher.subscribe(pusherChannel);
		channel.bind_global( (eventName, data) => {
			if( data.hasOwnProperty('action') ){

				if( data.action === 'prev' ){
					prevRef.current.click();
				}
				if( data.action === 'next' ){
					nextRef.current.click();
				}
			}
		});
		*/
	}

	const detectKeyboardInput = () => {
		document.addEventListener("keydown", (e) => {
			// console.log(e);
			// prev
			if( e.key === "ArrowLeft" || e.keyCode === 37 ){
				prevRef.current.click();
				prevRef.current.classList.add('fx');
				setTimeout(() => {
					prevRef.current.classList.remove('fx');
				}, 500);
			}
			// next
			if( e.key === "ArrowRight" || e.keyCode === 39 ){
				nextRef.current.click();
				nextRef.current.classList.add('fx');
				setTimeout(() => {
					nextRef.current.classList.remove('fx');
				}, 500);
			}
			// up - theme
			if( e.key === "ArrowUp" ){
				themeBtnRef.current.click();
			}
			// down - lang
			if( e.key === "ArrowDown" ){
				langBtnRef.current.click();
			}

			if( window.gtag !== undefined && window.gtag !== null ){
				window.gtag("event", "select_content", {
					content_type: "tv_swipe",
					content_id: e.key
				});
			}

		}, false);
	}

	const cycleTheme = () => {
		let difference = themes.length - 0;
		let rand = Math.random();
		rand = Math.floor( rand * difference);
		rand = rand + 0;

		setThemeId( rand );
	}

	const cycleLang = () => {
		const newLang = (themeLang === 'hr') ? 'en' : 'hr';
		setThemeLang( newLang );
	}

	const highlightItem = ( itemIdx ) => {
		let itemNavi = navigationRef.current.querySelector(`:scope > span:nth-of-type(${itemIdx+1})`);
		if( itemNavi !== null ){
			[...navigationRef.current.querySelectorAll('.active')].forEach( item => {
				item.classList.remove('active');
			});
			itemNavi.classList.add('active');
		}
	}

	const scrollToItem = ( itemIdx ) => {
		let item = itemsRef.current.querySelector(`:scope > div:nth-of-type(${itemIdx+1})`);
		if( item !== null ){
			scrollIntoView(item, {behavior: "smooth", block: "bottom", inline: "start" });
		}
	}

	const goToPrev = () => {
		clearTimeout(pageTimeout);

		let activeElem = navigationRef.current.querySelector('.hometv__content-navigation-item.active');
		let activeElemIdx = [...navigationRef.current.querySelectorAll('.hometv__content-navigation-item')].indexOf(activeElem);

		let newElemIdx = (activeElemIdx > 0 ) ? activeElemIdx-1 : data.length-1;
		let newElem = navigationRef.current.querySelector(`:scope > .hometv__content-navigation-item:nth-of-type(${newElemIdx+1})`);

		newElem.click();
		highlightItem(newElemIdx);
		scrollToItem( newElemIdx );

		/*
		setTimeout(() => {
			nextRef.current.click();
		}, pageIntervalTime);
		*/
	}

	const goToNext = () => {
		clearTimeout(pageTimeout);

		let activeElem = navigationRef.current.querySelector('.hometv__content-navigation-item.active');
		let activeElemIdx = [...navigationRef.current.querySelectorAll('.hometv__content-navigation-item')].indexOf(activeElem);

		let newElemIdx = (activeElemIdx < data.length-1 ) ? activeElemIdx+1 : 0;
		let newElem = navigationRef.current.querySelector(`:scope > .hometv__content-navigation-item:nth-of-type(${newElemIdx+1})`);

		newElem.click();
		highlightItem(newElemIdx);
		scrollToItem( newElemIdx );

		/*
		setTimeout(() => {
			nextRef.current.click();
		}, pageIntervalTime);
		*/
	}


	return (
		<Fragment>

			<Helmet>
				<title>{translations[props.lang].tv_title}</title>
			</Helmet>
			
			<div className="page page--hometv" data-theme={themes[themeId]} >

				<button hidden onClick={() => {cycleTheme()}} ref={themeBtnRef} />
				<button hidden onClick={() => {cycleLang()}} ref={langBtnRef} />

				<div className="hometv">
					<div className="hometv__content">

						<div className="hometv__content-head">
							<div>
								<h1>{translations[themeLang].tv_title}</h1>
								<p dangerouslySetInnerHTML={{__html: translations[themeLang].tv_subtitle}} />
							</div>
							<div>
								<span>{translations[themeLang].tv_url}</span>
								<img src="/qr.png" alt="QR" />
							</div>
						</div>

						<div className="hometv__content-middle">
							<div className="hometv__content-items" ref={itemsRef}>
								{data.map( (season, seasonIdx) => 
									<SeasonItem 
										key={`home_season_${seasonIdx}`} 
										season={season} 
										classPrefix="hometv"
										mediaParams="&force_format=png&width=700&height=700&func=crop"
										lang={props.lang}
									/>
								)}
							</div>
							<div className="hometv__content-navigation" ref={navigationRef}>
								{ data.map( (seasonObj, seasonIdx) => 
									<span 
										key={`menu_homepage_navigation_${seasonObj.id}`}
										className={`hometv__content-navigation-item ${(seasonIdx === 0)?'active':''}`}
										onClick={() => {scrollToItem(seasonIdx)}}
									/>
								)}	
							</div>
						</div>

						<div className="hometv__content-arrows">
							<button className="hometv__content-arrows-item hometv__content-arrows-item--left" onClick={goToPrev} ref={prevRef}>
								<IoIosArrowBack />
							</button>
							<button className="hometv__content-arrows-item hometv__content-arrows-item--right" onClick={goToNext} ref={nextRef}>
								<IoIosArrowForward />
							</button>
						</div>

					</div>
				</div>
			</div>
		
		</Fragment>
	);

}

export default HomeTv;