import { useEffect } from "react";
import Media from '../Media';



function ArticleHeroContent ( props ){

	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`} >

            { props.article.media.length > 0 && 
				<div className="article__hero">
					<Media 
						media={props.article.media[0]} 
						lightbox={false}
						cdnParams="&width=1400&height=900&func=crop"
					/>
				</div>
			}

            <div className="article__content">
                <div className="article__content-wrapper">
                    { props.article.title !== '' && 
                        <h2 id={props.article.slug}>
                            {props.article.title}
                        </h2>
                    }

                    { props.article.subtitle !== '' && 
                        <h3>{props.article.subtitle}</h3>
                    }

                    <p dangerouslySetInnerHTML={{__html: props.article.description}} />
                    { props.article.media
                        .filter( (elem,idx) => idx > 0 )
                        .map( (mediaItem, mediaItemIdx) => 
                        <div className="article__media"
                            key={`article_modal_media_${mediaItemIdx}`}>
                            <Media 
                                media={mediaItem}
                                lightbox={false}
                                cdnParams="&width=1200&func=crop"
                            />
                        </div>
                    )}
                </div>
            </div>
			
		</div>
	);

}

export default ArticleHeroContent;