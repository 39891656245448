import { Fragment, useEffect, useState, useRef } from "react";
import ArticlePropagator from "../ArticlePropagator";
import Media from '../Media';
import { useSwipeable } from "react-swipeable";
import { scrollIntoView } from "seamless-scroll-polyfill";
// import { translations } from '../../Helpers/Lang';
import { IoIosClose, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


function SectionGallery( props ){

    const imageRef = useRef();
    const thumbsRef = useRef();

	const [articleSelected, setArticleSelected] = useState(null);
    const [mediaSelected, setMediaSelected] = useState(null);

    const [canPrev, setCanPrev] = useState(false);
    const [canNext, setCanNext] = useState(false);

    const handlers = useSwipeable({
		onSwipedLeft: () => {
			nextMedia();
		},
		onSwipedRight: () => {
			prevMedia();
		}
	});

	useEffect(() => {
        checkHash();
        window.addEventListener('popstate', checkHash);
        
        return () => {
            window.removeEventListener('popstate', checkHash);
            document.querySelector('body').style.overflow = '';
        }
    // eslint-disable-next-line
	},[props.section.translations])

    useEffect(() => {
		if( articleSelected !== null ){
            document.querySelector('body').style.overflow = 'hidden';
        }else{
            document.querySelector('body').style.overflow = '';
        }
	},[articleSelected])

    useEffect(() => {
        checkPrevNext();
        scrollToThumb();
    // eslint-disable-next-line
    }, [mediaSelected]);


    const checkHash = () => {
        let hash = window.location.hash.replace('#', '');
        if( hash !== '' ){
            let selectedArticle = props.section.translations.find( x => x.slug === hash);
            selectArticle(selectedArticle);
        }else{
            closeArticle();
        }
    }

    const selectArticle = ( articleItem ) => {
        window.location.hash = `${articleItem.slug}`;
        setArticleSelected( articleItem );
        // first image (idx 0) is used as thumb on the list
        selectMedia( articleItem.media[1] );
    }

    const closeArticle = () => {
        window.location.hash = '';
        setArticleSelected( null );
    }

    const selectMedia = ( mediaItem ) => {
        setMediaSelected( null );
        setTimeout(() => {
            setMediaSelected( mediaItem );
        }, 10)
        
    }

    const prevMedia = () => {
        let items = articleSelected.media;
        let activeIdx = items.findIndex(x => x.id === mediaSelected.id);

        if( activeIdx > 0 ){
            selectMedia( items[activeIdx - 1] );
        }
    }

    const nextMedia = () => {
        let items = articleSelected.media;
        let activeIdx = items.findIndex(x => x.id === mediaSelected.id);

        if( activeIdx < items.length - 1 ){
            selectMedia( items[activeIdx + 1] );
        }
    }

    const checkPrevNext = () => {

        if( articleSelected !== null && mediaSelected !== null ){
            let items = articleSelected.media;
            let activeIdx = items.findIndex(x => mediaSelected !== null && x.id === mediaSelected.id);

            setCanPrev( 
                (activeIdx > 1) 
                    ? true 
                    : false 
            );

            setCanNext( 
                (activeIdx < items.length - 1) 
                    ? true 
                    : false 
            );
        }
    }

    const scrollToThumb = () => {
        if( articleSelected !== null && thumbsRef.current !== null && mediaSelected !== null ){
            scrollIntoView(thumbsRef.current.querySelector('.gallery-modal__thumb.active'), {behavior: "smooth", block: "nearest", inline: 'center' });
        }
    }


    let activeMediaIdx = 0;
    if( articleSelected !== null && mediaSelected !== null ){
        activeMediaIdx = articleSelected.media.findIndex(x => x.id === mediaSelected.id);
    }

	return (
		<div className={`section section--${props.section.display_type || 'default'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

            <ArticlePropagator
                key={`article_head`}
                translation={props.section.translations[0]}
                addClass="slider__item"
                {...props}
            />

                
                
            <div className="gallery">
                {props.section.translations
                    .filter( (x, transIdx) => transIdx > 0)
                    .map( (translation,translationIdx) => 
                    
                    <div key={`gallery_${translationIdx}`}
                        className="gallery__item"
                        onClick={() => selectArticle(translation)}
                    >
                        <h2>
                            {translation.title}
                            <small>{translation.media.length - 1} {translation.subtitle}</small>
                        </h2>
                        { translation.media.length > 0 && 
                            <div ref={imageRef}>
                                <Media 
                                    media={translation.media[0]}
                                    lightbox={false}
                                    lazyload
                                    cdnParams="&width=200&height=200&func=crop&gravity=face"
                                />
                            </div>
                        }
                    </div>
                )}
            </div>

            { articleSelected !== null && 

                <Fragment>

                    <div className="gallery-modal">

                        <button 
                            className="btn gallery-modal__close" 
                            onClick={() => closeArticle()}
                        >
                            <IoIosClose />
                        </button>

                        <div className="gallery-modal__wrapper">

                            <div className="gallery-modal__head">
                                <h2>{articleSelected.title}</h2>
                                <p>
                                    {activeMediaIdx} / {articleSelected.media.length-1} {articleSelected.subtitle}
                                </p>
                                
                            </div>
                            
                            { mediaSelected !== null && 
                                <div className="gallery-modal__media" {...handlers}>
                                    <div key={`gallery_media`}>
                                        <Media 
                                            media={mediaSelected}
                                            lightbox={false}
                                            lazyload
                                            loader
                                            cdnParams="&width=1500&func=crop"
                                        />
                                    </div>
                                </div>
                            }

                            <div className="gallery-modal__thumbs">
                                <div className="gallery-modal__thumbs-wrapper" ref={thumbsRef}>
                                    { articleSelected.media
                                        .filter((item, itemIdx) => itemIdx > 0)
                                        .map( (translationMedia,translationMediaIdx) => 
                                        <div key={`gallery_thumb_${translationMediaIdx}`}
                                            className={`gallery-modal__thumb ${(mediaSelected !== null && mediaSelected.id === translationMedia.id)?'active':''}`}
                                            onClick={() => { selectMedia(translationMedia)} }>
                                            <Media 
                                                media={translationMedia}
                                                lightbox={false}
                                                thumb={true}
                                                lazyload
                                                cdnParams="&width=70&height=70&func=crop&gravity=face"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <button 
                                className={`btn gallery-modal__navi-btn gallery-modal__navi-btn--prev ${(canPrev === false)?'disabled btn--disabled':''}`}
                                onClick={() => {prevMedia()}}>
                                <IoIosArrowBack />
                            </button>
                            
                            <button 
                                className={`btn gallery-modal__navi-btn gallery-modal__navi-btn--next ${(canNext === false)?'disabled btn--disabled':''}`}
                                onClick={() => {nextMedia()}}>
                                <IoIosArrowForward />
                            </button>

                        </div>
                    </div>

                </Fragment>
            }

		</div>
	);

}

export default SectionGallery;