export const languages = [
	'hr', 
	'en'
];

export const translations = {

	"hr" : {
		"title":					"Eurokaz Digitalni Arhiv",
		"title_short":				"Eurokaz",
		"subtitle":					"1987. - 2013.",
		"description":				"Eurokaz Digitalni Arhiv",

		"tv_title":					"Interaktivni digitalni arhiv - Eurokaz festival",
		"tv_subtitle":				"Projekt digitalizacije arhive Eurokaz Festivala od 1987. do 2013. godine.<br /><small class=\"tv-info\">Za listanje, lagano swipe-ajte lijevo/desno rukom ispred senzora, 30ak cm od prozora.<br />Swipe down for language change, swipe up to change color set.</small>",
		"tv_url":					"digitalarchive.eurokaz.hr",

		"terms": 					"Autori",
		"terms_slug": 				"autori",
		"terms_search":				"Pretraži autore",

		"search":					"Pretraga",
		"search_do":				"Pretraži",
		"search_placeholder":		"Unesi pojam za pretragu...",
		"search_reset":				"x",
		"search_count_result":		"rezultat",
		"search_count_results":		"rezultata",
		"search_no_results":		"Pretraga nema rezultata",
		"search_read_more":			"Pročitaj više...",

		"read_more":				"Više",
		"read_less":				"Zatvori",

		"not_found":				"Upsss",
		"not_found_content":		"Stranica nije nađena",

		"seasons": 					"Sezone",
		"season": 					"Sezona",

		"loading": 					"Učitavanje sadržaja...",

		"footer_left_html":			"<h2>Eurokaz Festival</h2><strong>1987. - 2013.</strong>",
		"footer_right_html":		"Sva prava pridržana.<br />Copyright Eurokaz &copy; 2023.<br /><br />Koordinacija: Gordana Vnuk<br />Digitalizacija: Iva Brižan<br />Website: Boris Vidošević",

		"label_next":				"Sljedeća sezona...",
		"label_article":			"Članak",
		"label_articles":			"Članci",

		"label_images":				"fotografije",
		"label_items":				"sadržaja",
	},

	"en" : {
		"title":					"Eurokaz Digital Archive",
		"title_short":				"Eurokaz",
		"subtitle":					"1987 - 2013",
		"description":				"Eurokaz Digital Archive",

		"tv_title":					"Interactive Digital Archive - Eurokaz Festival",
		"tv_subtitle":				"Project of digital archive of Eurokaz Festival, from 1987 to 2013.<br /><small class=\"tv-info\">Swipe left & right with your hand in front of the sensor, about 15 inch from the window.<br />Swipe down for language change, swipe up to change color set.</small>",
		"tv_url":					"digitalarchive.eurokaz.hr",

		"terms": 					"Authors",
		"terms_slug": 				"authors",
		"terms_placeholder":		"Search authors",

		"search":					"Search",
		"search_do":				"Search",
		"search_placeholder":		"Search Digital Archive",
		"search_reset":				"x",
		"search_count_result":		"result",
		"search_count_results":		"results",
		"search_no_results":		"Search found no results",
		"search_read_more":			"Read more...",

		"read_more":				"More",
		"read_less":				"Close",

		"not_found":				"Oupsss",
		"not_found_content":		"Page not found",

		"seasons": 					"Seasons",
		"season": 					"Season",

		"loading": 					"Loading content...",

		"footer_left_html":			"<h2>Eurokaz Festival</h2><strong>1987 - 2013</strong>",
		"footer_right_html":		"All Rights Reserved.<br />Copyright Eurokaz &copy; 2023.<br /><br />Coordination: Gordana Vnuk<br />Digitalization: Iva Brižan<br />Website: Boris Vidošević",

		"label_next":				"Next season...",
		"label_article":			"Article",
		"label_articles":			"Articles",

		"label_images":				"images",
		"label_items":				"items",
	},

}