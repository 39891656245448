import { Fragment, useEffect, useState } from "react";


import ArticleDefault from 				'./Articles/ArticleDefault';
import ArticleImgText from 				'./Articles/ArticleImgText';
import ArticleImgSlider from 			'./Articles/ArticleImgSlider';
import ArticleMediaGrid from 			'./Articles/ArticleMediaGrid';
import ArticleModal from 				'./Articles/ArticleModal';
import ArticleModalFull from 			'./Articles/ArticleModalFull';
import ArticleHeroContent from 			'./Articles/ArticleHeroContent';
import ArticleVideoFull from 			'./Articles/ArticleVideoFull';
import ArticleDescription from 			'./Articles/ArticleDescription';
import ArticleTwoHoriz from 			'./Articles/ArticleTwoHoriz';
import ArticleTextMediaColumns from 	'./Articles/ArticleTextMediaColumns';


function ArticlePropagator( props ){

	const [component, setComponent] = useState(null);

	useEffect(() => {

		const sections = {
			default:					ArticleDefault,
			article_imgtext:			ArticleImgText,
			article_imgslider:			ArticleImgSlider,
			article_mediagrid:			ArticleMediaGrid,
			article_modal:				ArticleModal,
			article_modalfull:			ArticleModalFull,
			article_herocontent:		ArticleHeroContent,
			article_videofull:			ArticleVideoFull,
			article_description:		ArticleDescription,
			article_twohoriz:			ArticleTwoHoriz,
			article_textmediacolumns:	ArticleTextMediaColumns,
		};

		let Component = sections[ props.translation.content_display ] || ArticleDefault;

		setComponent( 
			<Component 
				key={`comp_${Math.random()}`} 
				article={props.translation} 
				{...props}
			/>
		);

	// eslint-disable-next-line
	}, [props.translation])

	return (
		<Fragment>
		
			{ component !== null && {...component} }

		</Fragment>
	);

}

export default ArticlePropagator;