import { useEffect } from "react";
import Media from '../Media';



function ArticleImgText ( props ){

	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line
	},[])

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''}`} >

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

			{ props.article.title !== '' && 
				<h2 id={props.article.slug}>
					{props.article.title}
				</h2>
			}

			{ props.article.subtitle !== '' && 
				<h3>{props.article.subtitle}</h3>
			}

			{ props.article.media.length > 0 && 
				<div className="article__media">
					<Media 
						media={props.article.media[0]} 
						lightbox={false}
						cdnParams="&width=1200&func=crop"
					/>
				</div>
			}
			
			{ props.article.description !== '' && 
				<p dangerouslySetInnerHTML={{__html: props.article.description}} />
			}
			
		</div>
	);

}

export default ArticleImgText;