import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
// import ReactTooltip from 'react-tooltip';
import ReactTextTransition, {presets} from "react-text-transition";
import { cdnUrl, cdnParams } from '../Helpers/General';

// import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

// import Footer from '../Components/Footer';


function SeasonItem(props){

	const [fastIndex, setFastIndex] = useState(0);
	const [fastInterval, setFastInterval] = useState(null);
	
	const navigate = useNavigate();
	
	useEffect(() => {
		clearInterval(fastInterval);
		setFastInterval(null);
		setFastInterval(
			setInterval(() => {
				setFastIndex((prevCounter) => prevCounter + 1);
			}, 2000 + (Math.random()*1000))
		);
	// eslint-disable-next-line
	}, [props.season])


	const setPage = (page) => {
		navigate( `${process.env.PUBLIC_URL}/${props.lang}/${page}` );
		window.scrollTo(window.scrollX, 0);
	}

	const mediaParams = ( props.mediaParams !== undefined && typeof props.mediaParams === 'string' ) 
		? props.mediaParams 
		: '&force_format=png&width=200&height=200&func=crop';

	return (
		<Fragment>

            <div>
                { props.season !== undefined && props.season.hasOwnProperty('translations') && props.season.translations.length > 0 && 
                    <div className={`${props.classPrefix}__content-items-link`} onClick={() => setPage(props.season.slug)}>
                        { props.season.translations[0].media !== undefined && props.season.translations[0].media.length > 0 && 
							<div className={`${props.classPrefix}__content-items-media`} style={{ backgroundImage: `url(${cdnUrl}${encodeURIComponent(props.season.translations[0].media[0].path)}${cdnParams}${mediaParams})`}} />
                        }
						<div className={`${props.classPrefix}__content-items-info`} >
							<div>
								<h2 className="">{props.season.translations[0].title}</h2>
								<h3 className="fx">{props.season.translations[0].subtitle}</h3>
								{<p dangerouslySetInnerHTML={{__html: props.season.translations[0].description}}></p>}
							</div>
							<div className="animate" data-iscapture>
								{ props.season.terms !== undefined && props.season.terms.length > 0 && 
									<ReactTextTransition
										springConfig={presets.slow}
										className={`${props.classPrefix}__content-items-term`}
									>
										{props.season.terms[fastIndex % props.season.terms.length]}
									</ReactTextTransition>
								}
							</div>
						</div>
                    </div>
                }
            </div>
		
		</Fragment>
	);

}

export default SeasonItem;