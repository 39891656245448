import {Fragment, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import history from '../history';
import { Helmet } from 'react-helmet-async';
import { pageTransition, goTo404 } from '../Helpers/Func';
import { apiUrl } from '../Helpers/General';
import { translations } from '../Helpers/Lang';

import SectionPropagator from '../Components/SectionPropagator';

// import Media from '../Components/Media';
import Footer from '../Components/Footer';
import PageLinks from '../Components/PageLinks';



function Page(props){

	const navigate = useNavigate();

	const [page, 	setPage] = useState( null );

	useEffect(() => {
		pageTransition(); 
		window.scrollTo(0, 0);

		if( props.lang !== undefined && props.lang !== '' && props.menuLink.slug !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/pages/${props.menuLink.slug}`)
				.then(res => res.data)
				.then(data => {
					if( data.length <= 0 ){
						goTo404(props.lang, history, navigate);
					}else{
						setPage(data[0]);

						if( window.location.hash.replace('#','') !== '' ){
							setTimeout(() => {
								let hashScrollSelector = `[data-scroll-to-slug="${window.location.hash.replace('#','')}"]`;
								if( document.querySelector(hashScrollSelector) !== null ){
									document.querySelector(hashScrollSelector).scrollIntoView();
								}
							}, 500);
						}
					}
				});
		}
	// eslint-disable-next-line
	},[props.lang, props.menuLink])


	

	return (
		<Fragment>

			{ page !== null && 
				<Fragment>

					<Helmet>
						<title>{translations[props.lang].title_short} - {page.translations[0].title}</title>
					</Helmet>

					<div className={`page page--generic page--${page.slug}`}>
					
						<div className="page__content">
							<SectionPropagator 
								key={`page_${page.slug}`} 
								lang={props.lang}
								section={page} 
							/>
						</div>
						
						<PageLinks 
							page={page} 
						/>

					</div>

					<Footer 
						{...props} 
					/>
				</Fragment>
			}

		</Fragment>
	);

}

export default Page;