import { Fragment, useEffect, useState } from "react";
import ArticlePropagator from "../ArticlePropagator";
import Media from '../Media';
import { translations } from '../../Helpers/Lang';
import { IoIosClose } from "react-icons/io";
import { TbBook, TbZip, TbPhoto, TbVideo, TbHeadphones } from "react-icons/tb";


function SectionOther( props ){

	const [articleSelected, setArticleSelected] = useState(null);
    const [mediaSelected,   setMediaSelected] = useState(null);

	useEffect(() => {
        return () => {
            document.querySelector('body').style.overflow = '';
        }
	},[])

    useEffect(() => {
		if( articleSelected !== null ){
            document.querySelector('body').style.overflow = 'hidden';
        }else{
            document.querySelector('body').style.overflow = '';
        }
	},[articleSelected, mediaSelected])

    /*
    const selectArticle = ( articleItem ) => {
        setArticleSelected( articleItem );
    }
    */

    const closeArticle = () => {
        setArticleSelected( null );
    }

    const selectMedia = ( mediaItem ) => {
        setMediaSelected( mediaItem );
    }

    const closeMedia = () => {
        setMediaSelected( null );
    }

    const getMediaIcon = ( media ) => {
        if( media.embed !== '' ){
            return <TbVideo />;
        }
        else if( 	
            media.path.includes('.wav') 
            || media.path.includes('.mp3') 
		){
            return <TbHeadphones />;
        }
        else if( 	
            media.path.includes('.jpg') 
            || media.path.includes('.jpeg') 
            || media.path.includes('.png') 
            || media.path.includes('.gif') 
		){
            return <TbPhoto />;
        }
        else if( 
            media.path.includes('.pdf') 
            || media.path.includes('.doc') 
            || media.path.includes('.docx') 
        ){
            return <TbBook />
        }
        else if( media.path.includes('.zip') ){
            return <TbZip />
        }
        else{
            return 'unknown'
        }
    }


	return (
		<div className={`section section--${props.section.display_type || 'default'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

            <ArticlePropagator
                key={`article_head`}
                translation={props.section.translations[0]}
                addClass="slider__item"
                {...props}
            />

                
                
            <div className="gallery">
                {props.section.translations
                    .filter( (x, transIdx) => transIdx > 0)
                    .map( (translation,translationIdx) => 
                    
                    <div key={`gallery_${translationIdx}`}
                        className="gallery__item"
                        data-scroll-to-slug={translation.slug}
                        //onClick={() => selectArticle(translation)}
                    >
                        <h2>
                            {translation.title}
                            <small>{translation.media.length} {translations[props.lang].label_items}</small>
                        </h2>
                        { translation.media.length > 0 && 
                            <div className="gallery__item-links">
                                { translation.media.map( (transMedia, transMediaIdx) => 
                                    <button 
                                        key={`trans_media_${transMediaIdx}`}
                                        onClick={() => selectMedia(transMedia)}
                                    >
                                        {getMediaIcon(transMedia)} {transMedia.title}
                                    </button>
                                )}
                            </div>
                        }
                    </div>
                )}
            </div>

            { articleSelected !== null && 
                <Fragment>

                    <div className="gallery-modal">

                        <button 
                            className="btn gallery-modal__close" 
                            onClick={() => closeArticle()}
                        >
                            <IoIosClose />
                        </button>

                        <div className="gallery-modal__wrapper">

                            <div className="gallery-modal__head">
                                <h2>{articleSelected.title}</h2>
                                <h3>{articleSelected.subtitle}</h3>
                                <p dangerouslySetInnerHTML={{__html: articleSelected.description}} />
                            </div>

                            <div className="gallery-modal__content">
                                { articleSelected.media
                                    .map( (translationMedia,translationMediaIdx) => 
                                        <Media 
                                            key={`section_media_${translationMediaIdx}`}
                                            media={translationMedia}
                                            cdnParams="&width=50&height=50&func=crop"
                                        />
                                )}
                            </div>
                        </div>
                    </div>

                </Fragment>
            }

            { mediaSelected !== null && 
                <Fragment>

                    <div className="gallery-modal">

                        <button 
                            className="btn gallery-modal__close" 
                            onClick={() => closeMedia()}
                        >
                            <IoIosClose />
                        </button>

                        <div className="gallery-modal__wrapper">

                            <div className="gallery-modal__head">
                                <h2>{mediaSelected.title}</h2>
                            </div>

                            <div className="gallery-modal__content">
                                <Media 
                                    key={`section_media_${mediaSelected.id}`}
                                    media={mediaSelected}
                                    cdnParams="&width=1000&func=crop"
                                />
                            </div>
                        </div>
                    </div>

                </Fragment>
            }

		</div>
	);

}

export default SectionOther;