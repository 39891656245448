import Main from './Containers/Main';
import './Styles/index.scss';

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

function App() {

  if (window.location.hostname.includes("eurokaz.hr") ){
    Sentry.init({
      dsn: "https://39815bb5874c44aeabe079c802195c66@o563982.ingest.sentry.io/4503944381136896",
      integrations: [new BrowserTracing()],
      autoSessionTracking: false,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

	return (
    <div className="App">
      <Main />
    </div>
	);
}

export default App;