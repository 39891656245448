import { useState, useEffect, useRef, Children, cloneElement } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

let scrollTimeout = null;
let scrollTimeoutShort = null;

function SliderItem (props){
	return (
		<div 
			onClick={props.onClick}
			// {...props}
		>
			{props.item}
		</div>
	)
}

function Slider( props ){

	const itemsRef = useRef();
	const navigationRef = useRef();

	const [isInteracted, setIsInteracted] = useState(false);

	const [canPrev, setCanPrev] = useState(false);
	const [canNext, setCanNext] = useState(false);

  	const { events } = useDraggable(itemsRef, {
		applyRubberBandEffect: true,
		safeDisplacement: 100,
		isMounted: true
	});

	useEffect(() => {
		let elemContainer = itemsRef.current;

		if( elemContainer !== null ){
			elemContainer.addEventListener('mousedown', (e) => {
				elemContainer.classList.add('pressed');
				setIsInteracted(true);
			})
			elemContainer.addEventListener('mouseup', (e) => {
				elemContainer.classList.remove('pressed');
			})

			itemsRef.current.addEventListener('scroll', (e) => {

				if( scrollTimeout !== null ){
					clearTimeout( scrollTimeout );
					scrollTimeout = null;
				}
				if( scrollTimeoutShort !== null ){
					clearTimeout( scrollTimeoutShort );
					scrollTimeoutShort = null;
				}

				let itemToGo = null;

				// scrollTimeoutShort = setTimeout(() => {
					// find index of element to scroll to	
					let initialDiff = 999999999;
					let itemsLeft = itemsRef.current.getBoundingClientRect().left;
					let items = [...itemsRef.current.querySelectorAll(':scope > div')];
					items.forEach( (item, itemIdx) => {
						let itemLeft = item.getBoundingClientRect().left - itemsLeft + 1;
						// let itemRight = item.getBoundingClientRect().right;
						let itemWidth = item.getBoundingClientRect().width;
						if( Math.abs(itemLeft) < initialDiff){
							initialDiff = Math.abs(itemLeft);
							itemToGo = itemIdx;
						}else if( Math.abs(itemLeft + itemWidth) < initialDiff){
							initialDiff = Math.abs( itemLeft + itemWidth );
							itemToGo = itemIdx;
						}
						highlightItem(itemToGo);
					});
				// }, 100);

				scrollTimeout = setTimeout(() => {
					setCanPrev( itemToGo === 0 ? false : true );
					setCanNext( itemToGo === props.children.length-1 ? false : true );
				}, 300);


			}, false);
		}

		setTimeout(()=> {
			setCanNext(true);
		},500);

		return () => {
			if( elemContainer !== null ){
				elemContainer.scrollLeft = 0;
				elemContainer.removeEventListener('mousedown', {})
				elemContainer.removeEventListener('mouseup', {})
			}
		}
	// eslint-disable-next-line
	},[])


	const highlightItem = (itemIdx) => {
		let itemNavi = navigationRef.current.querySelector(`:scope > span:nth-of-type(${itemIdx+1})`);
		if( itemNavi !== null ){
			[...navigationRef.current.querySelectorAll('.active')].forEach( item => {
				item.classList.remove('active');
			});
			itemNavi.classList.add('active');
		}
	}

	const scrollToItem = ( itemIdx ) => {
		setCanPrev( itemIdx === 0 ? false : true );
		setCanNext( itemIdx === props.children.length-1 ? false : true );

		let item = itemsRef.current.querySelector(`:scope > div:nth-of-type(${itemIdx+1})`);
		if( item !== null ){
			let inlineSetting = ( itemIdx+1 === props.children.length ) ? 'start' : 'start';
			scrollIntoView(item, {behavior: "smooth", block: "nearest", inline: inlineSetting });
		}
	}

	const goToPrev = () => {
		let activeElem = navigationRef.current.querySelector('.slider__container-navigation-item.active');
		let activeElemIdx = [...navigationRef.current.querySelectorAll('.slider__container-navigation-item')].indexOf(activeElem);
		scrollToItem( activeElemIdx > 0 ? activeElemIdx-1 : 0 );
	}

	const goToNext = () => {
		let activeElem = navigationRef.current.querySelector('.slider__container-navigation-item.active');
		let activeElemIdx = [...navigationRef.current.querySelectorAll('.slider__container-navigation-item')].indexOf(activeElem);
		scrollToItem( activeElemIdx < props.children.length-1 ? activeElemIdx+1 : props.children.length-1 );
	}



	return (
		<div className={`slider slider--${props.type} slider--interacted-${(isInteracted===true) ? 'yes':'no'}`} >
			

			

			<div className="slider__container-prevnext">
				<button
					className={`slider__container-prevnext-prev ${(canPrev ? '' : 'disabled')}`} 
					disabled={!canPrev}
					onClick={goToPrev}>
					<IoIosArrowBack width={32} height={32} />
				</button>
				<button 
					className={`slider__container-prevnext-next ${(canNext ? '' : 'disabled')}`}
					disabled={!canNext}
					onClick={goToNext}>
					<IoIosArrowForward width={32} height={32} />
				</button>
			</div>

			<div className="slider__container-navigation" ref={navigationRef}>
				{ props.children.map( (seasonObj, seasonIdx) => {
						return <span 
							key={`slider-navigation_${seasonObj.key}`}
							className={`slider__container-navigation-item ${(seasonIdx === 0)?'active':''}`}
							onClick={() => {scrollToItem(seasonIdx)}}
							onMouseEnter={() => {
								
							}}
							onMouseLeave={() => {
								
							}}
						/>
					}
				)}	
			</div>

			<div className="slider__container" {...events} ref={itemsRef}>
				{ Children.toArray(props.children).map( (child, childIdx) =>
					cloneElement( 
						<SliderItem 
							key={`slider_item_${childIdx}`} 
							item={child} 
							onClick={(e) => { 
								e.target.classList.contains('btn') === false && scrollToItem(childIdx);
							}} 
							{...props}
						/>
					)
				)}
			</div>

		</div>
	);

}

export default Slider;