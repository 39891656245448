import { useEffect } from "react";
import Slider from '../Slider';
import Media from '../Media';



function SectionHorizontal( props ){

	useEffect(() => {
		//
	},[props.section])

	return (
		<div className={`section section--${props.section.display_type || 'horizontal'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} >

			<Slider 
				{...props}
				type="horizontal">
				{props.section.translations.map( (translation,translationIdx) => {
					return <div className="slider__item" 
								key={`translation_${translationIdx}`}>

						<small className="slider__item-count">{`${translationIdx+1}/${props.section.translations.length}`}</small>

						{ translation.title !== '' && 
							<h2 id={translation.slug}>
								{translation.title}
							</h2>
						}

						{ translation.subtitle !== '' && 
							<h3>{translation.subtitle}</h3>
						}

						{ translation.description !== '' && 
							<p dangerouslySetInnerHTML={{__html: translation.description}} />
						}

						{ translation.media.length > 0 && 
							<div className="section__media">
								{translation.media.map( (media, mediaIdx) => 
									<Media key={`media_${mediaIdx}`} media={media} />
								)}
							</div>
						}

					</div>
				})}
			</Slider>

		</div>
	);

}

export default SectionHorizontal;