import {Fragment, useEffect} from "react";
import { Helmet } from 'react-helmet-async';
import { NavLink } from "react-router-dom";
import { translations } from '../Helpers/Lang';
import { pageTransition } from '../Helpers/Func';


function NotFound(props){

	useEffect(() => {
		pageTransition(); 
		window.scrollTo(0, 0);
	// eslint-disable-next-line
	},[props.lang])

	return (
		<Fragment>

			<Helmet>
				<title>{translations[props.lang].title_short} - {translations[props.lang].not_found}</title>
			</Helmet>
		
			<div className="page page--not-found">
			
				<h1>{translations[props.lang].not_found}</h1>
				<p>{translations[props.lang].not_found_content}</p>

				<NavLink to={`/`}>{translations[props.lang].title}</NavLink>

			</div>

		</Fragment>
	);

}

export default NotFound;