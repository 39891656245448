import React, {Fragment, useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { languages, translations } from '../Helpers/Lang';
import { Divide as Hamburger } from 'hamburger-react'
// import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import { apiUrl, cdnUrl, cdnParams } from '../Helpers/General';

function Menu(props){

	const seasonsRef = useRef();

	const [data, setData] = useState([]);
	const [menuVisible, setMenuVisible] = useState(false);
	const [seasonsVisible, setSeasonsVisible] = useState(false);
	const [currentSlug, setCurrentSlug] = useState('');
	const [tooltip, setTooltip] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if( menuVisible === false ){
			setSeasonsVisible(false);
			document.querySelector('body').style.overflow = '';
		}else{
            document.querySelector('body').style.overflow = 'hidden';
        }
	}, [menuVisible]);

	useEffect(() => {
		if( props.lang !== undefined && props.lang !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/lists`)
				.then(res => res.data)
				.then(data => {
					setData(data);
				});
		}
	// eslint-disable-next-line
	},[props.lang])

	useEffect(() => {
		let urlSegments = window.location.href.split('/');
		if( urlSegments[4] !== undefined ){
			setCurrentSlug( urlSegments[4] );
		}else{
			setCurrentSlug( '' );
		}
	// eslint-disable-next-line
	},[window.location.href])

	const setLanguage = (lang) => {
		// setMenuVisible(false);
		props.setLang(lang);
		navigate(`/${lang}`);
		// window.scrollTo(window.scrollX, 0);
	}

	const setPage = (page) => {
		setMenuVisible(false);
		navigate( `${process.env.PUBLIC_URL}/${props.lang}/${page}` );
		window.scrollTo(window.scrollX, 0);
	}

	/*
	const toggleSeasons = () => {
		setSeasonsVisible( !seasonsVisible );
	}
	*/

	return (
		<Fragment>

			<button className={`menu-toggler ${(menuVisible)?'menu-toggler--opened':'menu-toggler--closed'}`} onClick={() => {setMenuVisible( !menuVisible );}}>
				<span className="menu-toggler__wrapper">
					<Hamburger 	size={32} 
								duration={0.4} 
								toggled={menuVisible}
								distance="sm" />
				</span>
			</button>

			<div className={`menu ${(menuVisible)?'menu--visible':'menu--invisible'}`}>
				<div className="menu__wrapper">

					{ props.lang !== undefined 
						&& props.lang !== '' 
						&& translations !== undefined 
						&& translations[props.lang] !== undefined
						&& 
						<Fragment>

							<div className="menu__items">
								
								<div className="menu__items-pages">
									{ props.menu !== undefined && props.menu.length > 0 && 
										props.menu.map( (menuLink, pageIdx) => {
											return <div key={`menu_page_${pageIdx}`}>
												<button
													className={`menu__item menu__item--page ${(currentSlug === menuLink.slug)?'active':''} `}
													onClick={ () => { setPage(menuLink.slug); }}
												>
													{menuLink.title}
												</button>

												{/* ( menuLink.slug === "/" || menuLink.slug === "" ) && 
													<button 
														className={`menu__item menu__item--toggler`} 
														onClick={toggleSeasons}>
														{ (seasonsVisible) 
															? <IoIosArrowBack />
															: <IoIosArrowForward />
														}
													</button>
												*/}

											</div>
										})
									}
								</div>
								<div className="menu__items-languages">
									{languages.map( language => {
										return <button
											key={`lang_${language}`}
											onClick={() => { setLanguage( language ); }} 
											className={`menu__item menu__item--language ${(props.lang=== language )?'active':''}`}>
											{language}
										</button>
									})}
								</div>
							</div>

							<div 
								ref={seasonsRef}
								className={`menu__seasons ${(seasonsVisible === true)?'menu__seasons--visible':'menu__seasons--hidden'}`}
							>
								{data.map(season => {

									let tooltipHtml = `<div class="tooltip__content">
										<p>${season.translations[0].title}</p>
										<p>${season.translations[0].subtitle}</p>
									</div>`;
									if ( season.translations[0].media !== undefined && season.translations[0].media.length > 0 ){
										let mediaItem = season.translations[0].media[0];
										tooltipHtml += `<div class="tooltip__media" 
											style="background-image: url(${cdnUrl}${encodeURIComponent(mediaItem.path)}${cdnParams}&force_format=png&width=200&height=200&func=crop);" 
										/>`;
									}
									return <button 
										key={`menu_${season.id}`}
										className={`menu__season ${(currentSlug === season.slug)?'active':''} `}
										onClick={() => {setPage(season.slug)}}

										data-tip={tooltipHtml}
										data-for={`tooltip-season`}
										data-html
										data-multiline
										data-iscapture

										onMouseEnter={() => setTooltip(true)}
										onMouseLeave={() => {
											setTooltip(false);
											setTimeout(() => setTooltip(true), 1);
										}}
									>
										{/*season.slug*/}
										{season.translations[0].title}
									</button>
								})}
							</div>

							{ tooltip === true && 
								<ReactTooltip  
									className="tooltip"
									type={'light'}
									offset={{top: 20}}
									id={`tooltip-season`} 
								/>
							}
							
						</Fragment>
					}

				</div>
			</div>

		</Fragment>
	);

}

export default Menu;